import React from "react";
import TagTypeFilterSelections from "./TagTypeFilterSelections";
import TagTypeFilterActionButtons from "./TagTypeFilterActionButtons";
const TagTypeTableFilter = ({
  clearAllSelection,
  handleSearch,
  filterValuesLogic,
  loadingTagTypes
}) => {
  const { selectionAddActions, filterValues } = filterValuesLogic;

  return (
    <div>
      <TagTypeFilterSelections
        selectionAddActions={selectionAddActions}
        filterValues={filterValues}
        handleSearch={handleSearch}
      />
      <TagTypeFilterActionButtons
        handleSearch={handleSearch}
        clearAllSelection={clearAllSelection}
        loadingData={loadingTagTypes.isLoading}
      />
    </div>
  );
};

export default TagTypeTableFilter;
