import React from "react";
import { useSearchEngineFilters } from "../logic/searchenginesFilters.logic";
import { useFetchSearchEngines } from "../logic/fetchSearchEngines.logic";
import { useSearchEngineTablelogic } from "../logic/searchEngineTable.logic";
// import SearchEngineTableTabMenu from "./SearchEngineTableTabMenu";
import Table from "../../../components/Table/Table";
import SearchEngineTableFilter from "./SearchEngineTableFilter";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";

const SearchEngineTable = ({ openEditForm }) => {
  const filterValuesLogic = useSearchEngineFilters();

  const {
    clearAllSelection,
    filterValues,
    // selectionAddActions,
    filtersClearToggle
  } = filterValuesLogic;

  const {
    loadingSearchEngines,
    searchEngines,
    page,
    setPage,
    size,
    setSize,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    total,
    getSearchEngines
  } = useFetchSearchEngines({
    filterValues,
    filtersClearToggle
  });

  // const { setArchivedData } = selectionAddActions;

  const {
    headers,
    actions,
    archiveModalOpen,
    deleteModalOpen,
    restoreModalOpen,
    handleModalClose,
    archiveSearchEngine,
    deleteSearchEngine,
    restoreSearchEngine
  } = useSearchEngineTablelogic({
    filterValues,
    getSearchEngines,
    openEditForm
  });

  return (
    <div className="flow-content">
      {/* Removing archived section for now */}
      {/* <SearchEngineTableTabMenu
        archivedData={filterValues.archivedData}
        setArchivedData={setArchivedData}
      /> */}

      <SearchEngineTableFilter
        handleSearch={getSearchEngines}
        clearAllSelection={clearAllSelection}
        filterValuesLogic={filterValuesLogic}
        loadingSearchEngines={loadingSearchEngines}
      />
      <div>
        {searchEngines && (
          <Table
            headers={headers}
            rowValues={searchEngines}
            actions={actions}
            isLoading={loadingSearchEngines.isLoading}
            noIds={true}
            noCheckboxes={true}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            total={total}
            rowsPerPageOptions={[10, 20, 30, 50, 100, 200]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            tableType="searchEngines"
          />
        )}
      </div>
      {archiveModalOpen && (
        <ConfirmBox
          title="Archive Search Engine"
          message="Are you sure you want to archive this search engine?"
          confirmAction={archiveSearchEngine}
          denyAction={handleModalClose}
        />
      )}
      {deleteModalOpen && (
        <ConfirmBox
          title="Delete Search Engine"
          message="Are you sure you want to delete this search engine?"
          confirmAction={deleteSearchEngine}
          denyAction={handleModalClose}
        />
      )}
      {restoreModalOpen && (
        <ConfirmBox
          title="Restore Search Engine"
          message="Are you sure you want to restore this search engine?"
          confirmAction={restoreSearchEngine}
          denyAction={handleModalClose}
        />
      )}
    </div>
  );
};

export default SearchEngineTable;
