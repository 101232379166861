import React, { useCallback, useEffect } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { Publisher } from "../../../services/Publisher";
import { useHistory } from "react-router";
import { toastr } from "react-redux-toastr";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";

export const useZapButtonLogic = (TargetingID) => {
  const [currentIndex, setCurrentIndex] = React.useState(null);
  const [targetingList, setTargetingList] = React.useState([]);
  const [loadingTargetingList, setLoadingTargetingList] = React.useState({
    loading: false,
    error: false
  });

  const isMounted = useIsMounted();

  const history = useHistory();

  const saveTargetingList = useCallback((data, currentPageID) => {
    const newTargetingList = [];
    data.forEach((item, index) => {
      newTargetingList.push(item.id);
      if (+item.id === +currentPageID) {
        setCurrentIndex(index);
      }
    });
    setTargetingList(newTargetingList);
  }, []);

  const fetchTargeting = useCallback(
    (currentPageID) => {
      setLoadingTargetingList({
        loading: true,
        error: false
      });
      Publisher.getTargeting({
        order_by: "id",
        order_direction: "ASC",
        size: DEFAULT_FETCH_PARAM_SIZE
      })
        .then((response) => {
          if (response.success && isMounted()) {
            const data = response.data.targetings;
            saveTargetingList(data, currentPageID);
            setLoadingTargetingList({
              loading: false,
              error: false
            });
          } else {
            setLoadingTargetingList({
              loading: false,
              error: true
            });
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          setTargetingList([]);
          console.trace(error.message);
        });
    },
    [saveTargetingList, isMounted]
  );

  useEffect(() => {
    if (TargetingID) {
      fetchTargeting(TargetingID);
    }
  }, [fetchTargeting, TargetingID]);

  const changeToNextTargeting = useCallback(
    (currentIndex, targetingList) => {
      if (currentIndex !== null && currentIndex < targetingList.length - 1) {
        history.push(`/target/edit/${targetingList[currentIndex + 1]}`);
      } else {
        toastr.warning("Warning", "You have reached the end of the list");
      }
    },
    [history]
  );

  const changeToPreviousTargeting = useCallback(
    (currentIndex, targetingList) => {
      if (currentIndex !== null && currentIndex > 0) {
        history.push(`/target/edit/${targetingList[currentIndex - 1]}`);
      } else {
        toastr.warning("Warning", "You have reached the beginning of the list");
      }
    },
    [history]
  );

  //action buttons to change the page
  const zapButtonAction = useCallback(
    (actionType) => {
      if (actionType === "next") {
        changeToNextTargeting(currentIndex, targetingList);
      } else {
        changeToPreviousTargeting(currentIndex, targetingList);
      }
    },
    [
      currentIndex,
      targetingList,
      changeToNextTargeting,
      changeToPreviousTargeting
    ]
  );

  const noOfTargeting = targetingList.length;

  return { loadingTargetingList, zapButtonAction, noOfTargeting };
};
