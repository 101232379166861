import React from "react";
import Table from "../../../components/Table/Table";
import { usePublisherAccountsTableLogic } from "../logic/publisherAccountTable.logic";

const PublisherAccountTable = ({ fetchpublisherAccountsLogic }) => {
  const {
    publisherAccounts,
    page,
    setPage,
    size,
    setSize,
    total,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    updateStatus,
    loadingpublisherAccounts
  } = fetchpublisherAccountsLogic;
  const { headers, actions } = usePublisherAccountsTableLogic();

  function handleStatusToggle(id, currentStatuses) {
    updateStatus(id, !currentStatuses);
  }

  // const updateValue = (id, key, value) => {
  //   let tempPublisherAccounts = [...pub_accounts];
  //   let changedIndex = tempPublisherAccounts.findIndex(
  //     (account) => account.id === id
  //   );
  //   tempPublisherAccounts[changedIndex][key] = value.status;
  //   dispatch(updateList(tempPublisherAccounts));
  // };

  return (
    <div>
      {publisherAccounts && (
        <Table
          headers={headers}
          rowValues={publisherAccounts || []}
          actions={actions}
          isLoading={loadingpublisherAccounts.isLoading}
          handleStatusToggle={handleStatusToggle}
          noCheckboxes={true}
          noIds={true}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
          total={total}
          rowsPerPageOptions={[10, 20, 30, 50, 100, 200]}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          tableType="publisheraccount"
        />
      )}
    </div>
  );
};

export default PublisherAccountTable;
