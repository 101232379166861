import React from "react";

const ContentHeader = ({ icon, title, additionalComponent = null }) => {
  return (
    <div className="content__header">
      <div className="content__header__icon">{icon}</div>
      <div className="content__header__text">
        <h2>{title}</h2>
      </div>
      {additionalComponent && additionalComponent}
    </div>
  );
};

export default ContentHeader;
