import React from "react";
import { useFilterAdvertiserMapping } from "../filterAdvertiserMapping";
import TextField from "@material-ui/core/TextField";
import AdvertiserListBox from "./AdvertiserListBox";

const AdvertiserListBoxContainer = ({ advertisers }) => {
  const { filteredAdvertisers, filterText, onFilterTextChange } =
    useFilterAdvertiserMapping(advertisers);

  return (
    <div className="csv-upload__container">
      <TextField
        variant="outlined"
        margin="normal"
        label="Type to filter through advertisers"
        onChange={onFilterTextChange}
        value={filterText}
        fullWidth
        className="csv-upload__filterInput"
      />
      <hr />
      {filteredAdvertisers.map((advertiser) => (
        <AdvertiserListBox key={advertiser.id} advertiser={advertiser} />
      ))}
    </div>
  );
};

export default AdvertiserListBoxContainer;
