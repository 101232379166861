import React from "react";

const NormalOption = ({
  option,
  getLabel,
  showValueInLabel,
  disabledMenuClassName,
  enabledMenuClassName
}) => {
  const isDisabled = option?.disabled || false;
  return (
    <div
      className={`autoselect-value ${
        isDisabled ? disabledMenuClassName : enabledMenuClassName
      }`}
    >
      <span>{getLabel(option, showValueInLabel)}</span>
    </div>
  );
};

export default NormalOption;
