import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ReportingLogs } from "../../services/reportingLogs";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { toastr } from "react-redux-toastr";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

export default function ReportingLogsTable() {
  const classes = useStyles();
  const history = useHistory();
  const [allReports, setAllReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formDate, setFormData] = useState({
    from: "",
    to: ""
  });

  useEffect(() => {
    fetchReports();
  }, []);

  async function fetchReports() {
    try {
      setLoading(true);
      const res = await ReportingLogs.fetchReports({});
      if (res) {
        setAllReports(res?.data?.skippedRows);
        setLoading(false);
      } else {
        throw new Error("Failed to fetch logs");
      }
    } catch (error) {
      toastr.error("Error", "Failed to fetch logs");
    } finally {
      setLoading(false);
    }
  }

  const vewMoreDetails = (event, id, action) => {
    event.preventDefault();
    if (action === "uploaded") {
      history.push(`/reportinglogs/${id}/uploaded`);
    } else {
      history.push(`/reportinglogs/${id}/skipped`);
    }
  };

  const submit = (event) => {
    event.preventDefault();
    setLoading(true);
    ReportingLogs.fetchReports(formDate).then((res) => {
      setAllReports(res?.data?.skippedRows);
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <form style={{ margin: "20px 0px" }}>
        <TextField
          id="date"
          label="From date"
          type="date"
          onChange={(e) => setFormData({ ...formDate, from: e.target.value })}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="date"
          label="To date"
          type="date"
          onChange={(e) => setFormData({ ...formDate, to: e.target.value })}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button
          onClick={(e) => submit(e)}
          variant="contained"
          color="primary"
          style={{ margin: "10px" }}
        >
          Search
        </Button>
      </form>
      <br />
      <TableContainer component={Paper}>
        {!loading ? (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#ID</TableCell>
                <TableCell align="center">Advertiser ID</TableCell>
                <TableCell align="center">CSV File</TableCell>
                <TableCell align="center">Message</TableCell>
                <TableCell align="center">CSV upload date</TableCell>
                <TableCell align="center">Uploaded</TableCell>
                <TableCell align="center">Skipped</TableCell>
                <TableCell align="center">Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allReports.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="center">{row?.advertiser_id}</TableCell>
                  <TableCell align="center">{row?.csvfile}</TableCell>
                  <TableCell align="center">{row?.message}</TableCell>
                  <TableCell align="center">
                    {moment(row?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                  </TableCell>
                  <TableCell align="center">
                    {row?.uploaded_fields.length}
                  </TableCell>
                  <TableCell align="center">{row?.total_excluded}</TableCell>
                  <TableCell align="center">
                    <Button
                      style={{ margin: "5px" }}
                      color="primary"
                      variant="contained"
                      onClick={(event) => {
                        vewMoreDetails(event, row.id, "skipped");
                      }}
                      disabled={row?.fields.length < 1}
                    >
                      Skipped Rows
                    </Button>
                    <Button
                      color="default"
                      variant="contained"
                      onClick={(event) => {
                        vewMoreDetails(event, row.id, "uploaded");
                      }}
                      disabled={row?.uploaded_fields.length < 1}
                    >
                      Uploaded Rows
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <h1 style={{ textAlign: "center" }}>Please Wait ....</h1>
        )}
      </TableContainer>
      <br />
    </React.Fragment>
  );
}
