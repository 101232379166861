import React from "react";
import MonthlyStats from "./MonthlyStats/MonthlyStats";

const AdminDashboard = () => {
  return (
    <main className="flow-content">
      <section className="admin-report-section">
        <h2 className="dashboard-subheading">Monthly Stats</h2>
        <MonthlyStats />
      </section>
    </main>
  );
};

export default AdminDashboard;
