import { useSelector } from "react-redux";
import { getCurrentUser } from "../helpers/AuthHelper";

export const useCurrentUser = () => {
  const currentUser = useSelector(getCurrentUser);

  return {
    currentUser
  };
};
