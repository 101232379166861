import React from "react";
// import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { STATUS } from "../constant/staticMessage";

const SkippedRowsFilterSelections = ({
  selectionAddActions,
  filterValues,
  // handleSearch,
  dataLists
}) => {
  const {
    // handleQueryChange,
    handleProviderChange,
    handleSearchFilesChange,
    // changeToLinked,
    // changeToNotLinked,
    setMessage
    // clearstatus
  } = selectionAddActions;

  const { selectedProviders, selectedFiles, message } = filterValues;

  const { providers, files } = dataLists;

  return (
    <>
      <div className="providerLinks-filter__selections">
        {/* <TextField
          variant="standard"
          margin="normal"
          label="Search By Channel"
          onKeyPress={(e) => {
            e.key === "Enter" && handleSearch();
          }}
          value={query}
          onChange={handleQueryChange}
          fullWidth
        /> */}

        <AutoCompleteSelect
          selected={selectedProviders}
          data={providers}
          handleChange={handleProviderChange}
          label={"Advertisers"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={true}
        />

        {selectedProviders.length > 0 && (
          <AutoCompleteSelect
            selected={selectedFiles}
            data={files}
            handleChange={handleSearchFilesChange}
            label={"csv files"}
            showValueInLabel={false}
            enableMultiple={true}
            withDotsForStatus={true}
          />
        )}
        <AutoCompleteSelect
          selected={message || ""}
          data={STATUS}
          handleChange={(value) => {
            setMessage(value);
          }}
          label={"Message"}
          showValueInLabel={false}
          enableMultiple={false}
          withDotsForStatus={false}
          cannotSelectDisabled={false}
        />

        <div
          style={{
            display: "flex",
            gap: "2rem"
          }}
        >
          {/* <div style={{ display: "flex" }}>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={status.value === true}
                    onChange={
                      status.value === true ? clearstatus : changeToLinked
                    }
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                label="Uploaded"
              />
            </div>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={status.value === false}
                    onChange={
                      status.value === false ? clearstatus : changeToNotLinked
                    }
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                label="Skipped"
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

SkippedRowsFilterSelections.propTypes = {
  selectionAddActions: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired
};

export default SkippedRowsFilterSelections;
