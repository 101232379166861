import React from "react";
import PropTypes from "prop-types";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

const FilterActionButtons = ({
  clearAllSelection,
  handleSearch,
  loadingDashboardData
}) => {
  return (
    <div className="dashboard-report__filters__action-buttons">
      <Button
        variant="contained"
        size="medium"
        color={loadingDashboardData ? "default" : "primary"}
        disabled={loadingDashboardData}
        onClick={handleSearch}
        startIcon={<SearchIcon />}
      >
        Search
      </Button>
      <Button
        variant="contained"
        size="medium"
        color={loadingDashboardData ? "default" : "secondary"}
        disabled={loadingDashboardData}
        onClick={clearAllSelection}
        startIcon={<ClearAllIcon />}
      >
        Clear All
      </Button>
    </div>
  );
};

FilterActionButtons.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  clearAllSelection: PropTypes.func.isRequired
};

export default FilterActionButtons;
