import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const NoMappedFieldMsg = ({ providerId }) => (
  <div className="csv-upload__no-mapped-msg dotted-box">
    <p className="upload-text">
      <Link
        to={{
          pathname: "/advertisers/edit/" + providerId,
          state: { providerId, type: "state" }
        }}
      >
        No mapped CSV Fields found. Open CSV Field Mapping Page.
      </Link>
    </p>
  </div>
);

NoMappedFieldMsg.propTypes = {
  providerId: PropTypes.number.isRequired
};

export default NoMappedFieldMsg;
