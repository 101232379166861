import { useRef, useEffect } from "react";

export const useRelaxedInterval = (callback, delay) => {
  const savedCallBack = useRef();

  useEffect(() => {
    savedCallBack.current = callback;
  }, [callback]);

  useEffect(() => {
    let running = false;
    let savedTimeout = null;

    const tick = async () => {
      if (running) {
        await savedCallBack.current();
      }

      if (running) {
        savedTimeout = setTimeout(tick, delay);
      }
    };

    const stop = () => {
      running = false;
      const timeout = savedTimeout;

      if (timeout !== null) {
        savedTimeout = null;
        clearTimeout(timeout);
      }
    };

    if (delay !== null && !savedTimeout) {
      running = true;
      savedTimeout = setTimeout(tick, delay);
      return stop;
    }
  }, [delay]);
};
