import Modal from "@material-ui/core/Modal";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "unset",
    outline: "none",
    borderRadius: "5px"
  }
}));

export const SimpleModal = ({ children, handleClose }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const modalBody = (
    <div style={modalStyle} className={classes.paper}>
      {children}
    </div>
  );

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modalBody}
    </Modal>
  );
};

export default SimpleModal;
