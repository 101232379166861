import { useState, useEffect } from "react";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { Platform } from "../../../services/Platform";

export const useFetchPlatforms = () => {
  const [platforms, setPlatforms] = useState([]);

  const [loadingPlatforms, setLoadingPlatforms] = useState({
    isLoading: false,
    error: false
  });

  const isMounted = useIsMounted();

  function getParams() {
    let params = {};

    params["order_by"] = "id";
    params["order_direction"] = "ASC";
    params["size"] = DEFAULT_FETCH_PARAM_SIZE;

    return params;
  }

  async function getPlatforms() {
    try {
      setLoadingPlatforms({ isLoading: true, error: false });
      const platforms = await Platform.fetchPlatforms(getParams());
      if (platforms.success && isMounted()) {
        let receivedPlatforms = [];
        for (let p of platforms.data.platforms) {
          receivedPlatforms.push({
            label: p.name,
            value: p.id,
            status: p.status
          });
        }
        setPlatforms(receivedPlatforms);
        setLoadingPlatforms({ isLoading: false, error: false });
      } else {
        throw new Error(platforms.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setPlatforms([]);
        setLoadingPlatforms({ isLoading: false, error: true });
      }
      console.trace(error.message || error);
    }
  }

  useEffect(() => {
    getPlatforms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loadingPlatforms,
    platforms
  };
};
