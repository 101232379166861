import { useState, useCallback, useEffect } from "react";
import { useTextInput } from "../../../hooks/useTextInput";
import { useMultipleSelect } from "../../../hooks/useMultipleSelect";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";
import { Provider } from "../../../services/Provider";
import { Publisher } from "../../../services/Publisher";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

import {
  ADMIN_ADVERTISER_ID,
  ADMIN_ADVERTISER_FROM_DATE,
  ADMIN_ADVERTISER_INTERVAL
} from "../../../helpers/constant/localStorage";
import { MONTH_TO_DATE } from "../../../helpers/constant/filters";
import { findObject } from "../../../helpers";

export const usePublisherAccountsFilters = () => {
  const [query, handleQueryChange, setQuery] = useTextInput({
    initialValue: ""
  });

  const [localStorageAdvertiserId, setLocalStorageAdvertiserId] =
    useLocalStorage(ADMIN_ADVERTISER_ID, "");

  const [localStorageFromDate, setLocalStorageFromDate] = useLocalStorage(
    ADMIN_ADVERTISER_FROM_DATE,
    ""
  );

  const [localStorageInterval, setLocalStorageInterval] = useLocalStorage(
    ADMIN_ADVERTISER_INTERVAL,
    ""
  );

  const {
    setData: setProviders,
    selectedData: selectedProviders,
    data: providers,
    handleSelectedDataChange: handleProviderChange,
    removeSelectedDataFromList: removeProviderFromList,
    removeAll: removeAllProviders
  } = useMultipleSelect();

  const {
    setData: setPublishers,
    selectedData: selectedPublishers,
    data: publishers,
    handleSelectedDataChange: handleSearchPublisherChange,
    removeSelectedDataFromList: removeSearchPublisherFromList,
    removeAll: removeAllPublisherEngines
  } = useMultipleSelect();

  const {
    setData: setTagIds,
    selectedData: selectedTagIds,
    data: tagIds,
    handleSelectedDataChange: handleTagIdChange,
    removeSelectedDataFromList: removeTagIdFromList,
    removeAll: removeAllTagIds
  } = useMultipleSelect();

  const {
    setData: setRuleIds,
    selectedData: selectedRuleIds,
    data: ruleIds,
    handleSelectedDataChange: handleRuleIdChange,
    removeSelectedDataFromList: removeRuleIdFromList,
    removeAll: removeAllRuleIds
  } = useMultipleSelect();

  const [filtersClearToggle, setFiltersClearToggle] = useState(false);

  function clearAllSelection() {
    setQuery("");
    removeAllProviders();
    removeAllPublisherEngines();
    removeAllTagIds();
    removeAllRuleIds();
    setFiltersClearToggle(!filtersClearToggle);
  }

  let filterValues = {
    query,
    selectedProviders,
    selectedPublishers,
    selectedTagIds,
    selectedRuleIds
  };

  let selectionAddActions = {
    handleQueryChange,
    handleProviderChange,
    handleSearchPublisherChange,
    handleTagIdChange,
    handleRuleIdChange
  };

  let dataLists = {
    providers,
    publishers,
    tagIds,
    ruleIds
  };

  const fetchProviders = useCallback(() => {
    Provider.fetchProviders({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.providers;
          const providers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            providers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setProviders(providers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPublishers = useCallback(() => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            publishers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setPublishers]);

  const fetchTargeting = useCallback(() => {
    Publisher.getTargeting({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.targetings;
          const targetings = [];
          for (let t of data) {
            let isInactive = !t.is_active;
            targetings.push({
              label: t.id,
              value: t.id,
              status: t.status,
              disabled: isInactive
            });
          }
          setTagIds(targetings);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setTagIds]);

  const fetchTargetingRules = useCallback(() => {
    Publisher.fetchTargetingRules()
      .then((response) => {
        if (response.success) {
          const data = response.data.targetingRules;
          const targetingRules = [];
          for (let t of data) {
            targetingRules.push({
              label: t.id,
              value: t.id,
              disabled: t.disabled
            });
          }
          setRuleIds(targetingRules);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setRuleIds]);

  useEffect(() => {
    fetchProviders();
    fetchPublishers();
    fetchTargeting();
    fetchTargetingRules();
  }, [fetchProviders, fetchPublishers, fetchTargeting, fetchTargetingRules]);

  useEffect(() => {
    if (providers && providers.length > 0) {
      if (
        findObject(providers, localStorageAdvertiserId) &&
        localStorageAdvertiserId &&
        localStorageFromDate
      ) {
        handleProviderChange([localStorageAdvertiserId]);
        setLocalStorageInterval("");
        setFiltersClearToggle(!filtersClearToggle);
        setLocalStorageAdvertiserId("");
        setLocalStorageFromDate("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers]);

  return {
    clearAllSelection,
    filterValues,
    selectionAddActions,
    filtersClearToggle,
    dataLists,
    removeProviderFromList,
    removeSearchPublisherFromList,
    removeTagIdFromList,
    removeRuleIdFromList,
    MONTH_TO_DATE,
    localStorageInterval
  };
};
