import React from "react";

const ReportFallback = () => {
  return (
    <div className="error-msg-box">
      Oops! Current facing some errors loading the report. Please try refreshing
      the page.
    </div>
  );
};

export default ReportFallback;
