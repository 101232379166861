import {
  COUNTRIES,
  INTERVALS_REPORT_ADMIN,
  GROUP_BY_REPORT_PUBLISHER,
  PERIODS
} from "../../../../helpers/constant/filters";
import { useMultipleSelect } from "../../../../hooks/useMultipleSelect";
import { useSelect } from "../../../../hooks/useSelect";
import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Platform } from "../../../../services/Platform";
import { TagType } from "../../../../services/TagType";
import { SearchEngine } from "../../../../services/SearchEngine";
import {
  LINK_PLATFORM,
  SEARCH_ENGINE,
  TAG_TYPE
} from "../../../../helpers/constant/filters";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../../helpers/constant/misc";
export const useReportFiltersLogic = ({ displayColumns }) => {
  const [orderBy, setOrderBy] = useState("");
  const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC

  const {
    setData: setCountriesData,
    selectedData: selectedCountries,
    data: countries,
    handleSelectedDataChange: handleCountryChange,
    removeSelectedDataFromList: removeCountryFromList,
    removeAll: removeAllCountries
  } = useMultipleSelect();

  const {
    setData: setIntervalData,
    selectedData: selectedInterval,
    data: intervals,
    changeSelectedData: changeSelectedInterval,
    clearSelection: clearSelectedInterval
  } = useSelect();

  const {
    setData: setPeriodData,
    selectedData: selectedPeriod,
    data: periods,
    changeSelectedData: changeSelectedPeriod,
    clearSelection: clearSelectedPeriod
  } = useSelect();

  const {
    setData: setGroupByData,
    selectedData: selectedGroupBy,
    data: groupByList,
    handleSelectedDataChange: handleGroupByChange,
    removeSelectedDataFromList: removeGroupByFromList,
    removeAll: removeAllGroupBy
  } = useMultipleSelect();

  const {
    setData: setPlatforms,
    selectedData: selectedPlatforms,
    data: platforms,
    handleSelectedDataChange: handlePlatformChange,
    removeSelectedDataFromList: removePlatformFromList,
    removeAll: removeAllPlatforms
  } = useMultipleSelect();

  const {
    setData: setSearchEngines,
    selectedData: selectedSearchEngines,
    data: searchEngines,
    handleSelectedDataChange: handleSearchEngineChange,
    removeSelectedDataFromList: removeSearchEngineFromList,
    removeAll: removeAllSearchEngines
  } = useMultipleSelect();

  const {
    setData: setTagTypes,
    selectedData: selectedTagTypes,
    data: tagTypes,
    handleSelectedDataChange: handleTagTypeChange,
    removeSelectedDataFromList: removeTagTypeFromList,
    removeAll: removeAllTagTypes
  } = useMultipleSelect();

  const [filtersClearToggle, setFiltersClearToggle] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleToDate = (date) => {
    //date to be set is null , set it and return
    if (!date) {
      setToDate(null);
      return;
    }
    if (!fromDate) {
      setToDate(date);
    } else {
      if (moment(date).isAfter(fromDate) || moment(date).isSame(fromDate)) {
        setToDate(date);
      } else setToDate(fromDate);
    }
  };

  const handleFromDate = (date) => {
    //date to be set is null , set it and return
    if (!date) {
      setFromDate(null);
      return;
    }
    if (!toDate) {
      setFromDate(date);
    } else {
      if (moment(date).isBefore(toDate) || moment(date).isSame(toDate)) {
        setFromDate(date);
      } else {
        setToDate(date);
        setFromDate(date);
      }
    }
  };

  const handleDateChange = (fromDate, toDate) => {
    handleFromDate(fromDate);
    handleToDate(toDate);
  };

  const fetchPlatforms = useCallback(() => {
    Platform.fetchPlatforms({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.platforms;
          const platforms = [];
          for (let p of data) {
            platforms.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setPlatforms(platforms);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setPlatforms]);

  const fetchSearchEngines = useCallback(() => {
    SearchEngine.fetchSearchEngines({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.search_engines;
          const searchEngines = [];
          for (let p of data) {
            searchEngines.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setSearchEngines(searchEngines);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setSearchEngines]);

  const fetchTagTypes = useCallback(() => {
    TagType.fetchTagTypes({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.tag_types;
          const tagTypes = [];
          for (let p of data) {
            tagTypes.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setTagTypes(tagTypes);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setTagTypes]);

  /**
   * setting list values that don't depend on display options
   */
  useEffect(() => {
    setCountriesData(COUNTRIES);
    setIntervalData(INTERVALS_REPORT_ADMIN);
    setGroupByData(GROUP_BY_REPORT_PUBLISHER);
    setPeriodData(PERIODS);
  }, [setCountriesData, setIntervalData, setGroupByData, setPeriodData]);

  // setting list values that depend on display options
  useEffect(() => {
    if (displayColumns.includes(LINK_PLATFORM)) {
      fetchPlatforms();
    }
    if (displayColumns.includes(SEARCH_ENGINE)) {
      fetchSearchEngines();
    }
    if (displayColumns.includes(TAG_TYPE)) {
      fetchTagTypes();
    }
  }, [displayColumns, fetchPlatforms, fetchSearchEngines, fetchTagTypes]);

  function clearAllSelection() {
    clearSelectedInterval();
    removeAllCountries();
    removeAllGroupBy();
    removeAllTagTypes();
    removeAllPlatforms();
    removeAllSearchEngines();
    setFromDate(null);
    setToDate(null);
    setFiltersClearToggle(!filtersClearToggle);
    clearSelectedPeriod();
  }

  let filterValues = {
    selectedCountries,
    selectedInterval,
    selectedGroupBy,
    fromDate,
    toDate,
    selectedTagTypes,
    selectedPlatforms,
    selectedSearchEngines,
    selectedPeriod,
    orderBy,
    orderDirection
  };

  let dataLists = {
    countries,
    intervals,
    groupByList: groupByList.filter((element) =>
      displayColumns.includes(element.value)
    ),
    tagTypes,
    platforms,
    searchEngines,
    periods
  };

  let selectionAddActions = {
    handleCountryChange,
    handleGroupByChange,
    changeSelectedInterval,
    handleFromDate,
    handleToDate,
    handleDateChange,
    handleTagTypeChange,
    handlePlatformChange,
    handleSearchEngineChange,
    changeSelectedPeriod,
    setOrderBy,
    setOrderDirection
  };

  return {
    filterValues,
    dataLists,
    selectionAddActions,
    clearAllSelection,
    filtersClearToggle,
    removeCountryFromList,
    removeGroupByFromList,
    removeTagTypeFromList,
    removePlatformFromList,
    removeSearchEngineFromList
  };
};
