import React from "react";
import PlatformForm from "./layouts/PlatformForm";
import PlatformTable from "./layouts/PlatformTable";
import { useFormAndTableSwitch } from "../../hooks/formAndTableSwitch.logic";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
const Platform = () => {
  const { currentPage, openAddForm, openEditForm, openTable } =
    useFormAndTableSwitch();

  return (
    <div>
      {currentPage.page === "form" && (
        <PlatformForm
          platformId={currentPage.pageData.id ? currentPage.pageData.id : ""}
          openTable={openTable}
        />
      )}
      {currentPage.page === "table" && (
        <div
          className="flow-content"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px"
          }}
        >
          <Button
            onClick={openAddForm}
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddBoxIcon />}
          >
            Add New Platform
          </Button>
          <PlatformTable openEditForm={openEditForm} />
        </div>
      )}
    </div>
  );
};

export default Platform;
