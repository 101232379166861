export const STATUS = [
  {
    label: "Failed",
    value: "Failed"
  },
  {
    label: "Partially Uploaded",
    value: "Partially Uploaded"
  },
  {
    label: "Uploaded Successfully",
    value: "Uploaded Successfully"
  },
  {
    label: "Zero Records Uploaded",
    value: "Zero Records Uploaded"
  },
  {
    label: "Processing",
    value: "Processing"
  },
  {
    label: "Initialized",
    value: "Constant"
  }
];
