import React, { useRef, useState } from "react";
import PhotoIcon from "@material-ui/icons/Photo";
import Button from "@material-ui/core/Button";
import { toastr } from "react-redux-toastr";
import { User } from "../../../services/User";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const ProfilePhotoUpdate = ({ userID, refreshProfileInfo, userRoleID }) => {
  const [uploadPhoto, setUploadPhoto] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  //ref for hidden input button of type file
  const fileUploadButton = useRef();

  //using ref value to open file upload dialog box
  const handleBrowseAction = (e) => {
    e.stopPropagation();
    fileUploadButton.current.click();
  };
  const handleUploadChange = (e) => {
    updateUploadPhoto(fileUploadButton.current.files[0]);
  };

  //File Drag and Drop event handler
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e?.dataTransfer?.files?.[0] && updateUploadPhoto(e.dataTransfer.files[0]);
  };
  //upload file temporarily to the state
  const updateUploadPhoto = (file) => {
    if (validateFile(file)) {
      setUploadPhoto(file);
    }
  };

  const validateFile = (file) => {
    let isValidFile = true;
    let fileType = file.type;
    let fileSize = file.size / 1024; //KB
    let fileSizeLimit = 3080; //KB i.e 5mb
    let validExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/webp"
    ];
    //checking if File is of image type
    if (!validExtensions.includes(fileType)) {
      isValidFile = false;
      toastr.warning("Oops!", "File not of Image Type");
      return isValidFile;
    }
    if (fileSize >= fileSizeLimit) {
      isValidFile = false;
      toastr.warning(
        "Oops!",
        `File Size cannot exceed ${(fileSizeLimit / 1024).toFixed(2)}MB`
      );
      return isValidFile;
    }
    return isValidFile;
  };

  const uploadProfilePhoto = () => {
    if (!uploadPhoto || !userID) {
      return;
    }

    var formData = new FormData();
    formData.append("profile_image", uploadPhoto);
    formData.append("role_id", userRoleID);
    setIsUploading(true);
    User.updateProfilePhoto(userID, formData)
      .then((response) => {
        if (response.success) {
          setIsUploading(false);
          refreshProfileInfo();
          toastr.success("Success", "Profile photo updated successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        setIsUploading(false);
        console.trace(error.message);
        toastr.error("Oops", JSON.parse(error.message).message);
      });
  };

  return (
    <div className="photo-upload">
      <h3 className="photo-upload__title">Select profile photo</h3>
      {isUploading ? (
        <div className="photo-upload__loading">
          <LoadingSpinner />
        </div>
      ) : (
        <div
          className="photo-upload__drop-area"
          onClick={handleBrowseAction}
          draggable="true"
          onDrop={(e) => handleDrop(e)}
          onDragOver={(e) => handleDragOver(e)}
          onDragEnter={(e) => handleDragEnter(e)}
          onDragLeave={(e) => handleDragLeave(e)}
        >
          <span className="photo-upload__drop-area__icon">
            {!uploadPhoto && <PhotoIcon fontSize="inherit" />}
            {uploadPhoto && (
              <img
                className="photo-upload__drop-area__image"
                src={URL.createObjectURL(uploadPhoto)}
                alt=""
              />
            )}
          </span>
          <span className="photo-upload__drop-area__text">
            Drag a profile photo here
            <br />
            -or-
            <br />
          </span>
          <button
            onClick={handleBrowseAction}
            className="photo-upload__drop-area__select-btn"
          >
            Select a photo from your device
          </button>
          <input
            ref={fileUploadButton}
            type="file"
            onChange={handleUploadChange}
            hidden
            accept="image/*"
          />
        </div>
      )}

      <Button
        color="primary"
        variant="contained"
        size="medium"
        className="photo-upload__upload-btn"
        onClick={uploadProfilePhoto}
        disabled={isUploading}
      >
        Set as profile photo
      </Button>
    </div>
  );
};

export default ProfilePhotoUpdate;
