import { useState, useEffect } from "react";
import { CsvUpload } from "../../../services/CsvUpload";
import { toastr } from "react-redux-toastr";
/**
 * Custom Hook to load list of Advertiser with mapping
 */
export const useGetAdvertiserWithMapping = () => {
  const [advertisers, setAdvertisers] = useState([]);
  const [loadingAdvertisers, setLoadingWithAdvertisers] = useState(false);

  function getAdvertisersWithMapping() {
    setLoadingWithAdvertisers(true);
    CsvUpload.fetchAdvertiserWithMapping()
      .then((response) => {
        if (response.success) {
          const { advertisers } = response.data; //advertisers from backend
          setAdvertisers(advertisers);
          setLoadingWithAdvertisers(false);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        setLoadingWithAdvertisers(false);
        toastr.error("Opps", "Unable to load list of advertisers with mapping");
        console.trace(error.message);
      });
  }

  useEffect(() => {
    getAdvertisersWithMapping();
  }, []);

  return {
    advertisers,
    loadingAdvertisers
  };
};
