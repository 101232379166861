import React from "react";
import Table from "../../../components/Table/Table";
import { useSkippedRowsTableLogic } from "../logic/skippedRowsTable.logic";

const SkippedRowsTable = ({ fetchskippedRowsLogic }) => {
  const {
    skippedRows,
    page,
    setPage,
    size,
    setSize,
    total,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    updateStatus,
    loadingskippedRows
  } = fetchskippedRowsLogic;
  // eslint-disable-next-line
  const { headers } = useSkippedRowsTableLogic();

  function handleStatusToggle(id, currentStatuses) {
    updateStatus(id, !currentStatuses);
  }

  return (
    <div>
      {skippedRows && (
        <Table
          headers={headers}
          rowValues={skippedRows || []}
          noActions={true}
          isLoading={loadingskippedRows.isLoading}
          handleStatusToggle={handleStatusToggle}
          noCheckboxes={true}
          noIds={true}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
          total={total}
          rowsPerPageOptions={[10, 20, 30, 50, 100, 200]}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          tableType="skippedrows"
        />
      )}
    </div>
  );
};

export default SkippedRowsTable;
