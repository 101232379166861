import { useState, useCallback, useEffect } from "react";
import { useTextInput } from "../../../hooks/useTextInput";
import { useMultipleSelect } from "../../../hooks/useMultipleSelect";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";
import { Provider } from "../../../services/Provider";
import { SkippedRows } from "../../../services/skippedRows";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

import {
  ADMIN_PROVIDER_ID,
  ADMIN_PROVIDER_FROM_DATE,
  ADMIN_PROVIDER_INTERVAL,
  ADMIN_CSVNAME
} from "../../../helpers/constant/localStorage";
import { MONTH_TO_DATE } from "../../../helpers/constant/filters";
import { findObject } from "../../../helpers";

export const useSkippedRowsFilters = () => {
  const [query, handleQueryChange, setQuery] = useTextInput({
    initialValue: ""
  });

  const [localStorageAdvertiserId, setLocalStorageAdvertiserId] =
    useLocalStorage(ADMIN_PROVIDER_ID, "");

  const [localStorageCsvname, setLocalStorageCsvname] = useLocalStorage(
    ADMIN_CSVNAME,
    ""
  );

  const [localStorageFromDate, setLocalStorageFromDate] = useLocalStorage(
    ADMIN_PROVIDER_FROM_DATE,
    ""
  );

  const [localStorageInterval, setLocalStorageInterval] = useLocalStorage(
    ADMIN_PROVIDER_INTERVAL,
    ""
  );

  const [status, setstatus] = useState({
    value: null
  });

  const [message, setMessage] = useState("");

  function changeToLinked() {
    setstatus({
      value: true
    });
  }

  function changeToNotLinked() {
    setstatus({
      value: false
    });
  }

  function clearstatus() {
    setstatus({
      value: null
    });
  }

  const {
    setData: setProviders,
    selectedData: selectedProviders,
    data: providers,
    handleSelectedDataChange: handleProviderChange,
    removeSelectedDataFromList: removeProviderFromList,
    removeAll: removeAllProviders
  } = useMultipleSelect();

  const {
    setData: setFiles,
    selectedData: selectedFiles,
    data: files,
    handleSelectedDataChange: handleSearchFilesChange,
    removeSelectedDataFromList: removeSearchFilesFromList,
    removeAll: removeAllFilesEngines
  } = useMultipleSelect();

  const [filtersClearToggle, setFiltersClearToggle] = useState(false);

  function clearAllSelection() {
    setQuery("");
    removeAllProviders();
    removeAllFilesEngines();
    clearstatus();
    setMessage("");
    setFiltersClearToggle(!filtersClearToggle);
  }

  let filterValues = {
    query,
    selectedProviders,
    selectedFiles,
    status,
    message
  };

  let selectionAddActions = {
    handleQueryChange,
    handleProviderChange,
    handleSearchFilesChange,
    changeToLinked,
    changeToNotLinked,
    clearstatus,
    setMessage
  };

  let dataLists = {
    providers,
    files
  };

  const fetchProviders = useCallback(() => {
    Provider.fetchProviders({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.providers;
          const providers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            providers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setProviders(providers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchfiles = useCallback(() => {
    const params = {
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    };
    if (selectedProviders?.length > 0) {
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["provider_id"] = selection;
    }
    SkippedRows.fetchcsvFiles({
      ...params
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.files;
          const files = [];
          for (let p of data) {
            files.push({
              label: p.csvfile,
              value: p.csvfile
            });
          }
          setFiles(files);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [selectedProviders, setFiles]);

  useEffect(() => {
    fetchProviders();
    fetchfiles();
  }, [fetchProviders, fetchfiles]);

  const changeFileProviderStatus = useCallback(() => {
    if (providers && providers.length > 0) {
      if (
        findObject(providers, localStorageAdvertiserId) &&
        localStorageAdvertiserId &&
        localStorageFromDate
      ) {
        handleProviderChange([localStorageAdvertiserId]);
        setLocalStorageInterval("");
        setFiltersClearToggle(!filtersClearToggle);
        setLocalStorageAdvertiserId("");
        setLocalStorageFromDate("");
      }
    }
    if (files && files.length > 0) {
      if (
        findObject(files, localStorageCsvname) &&
        localStorageCsvname &&
        localStorageFromDate
      ) {
        handleSearchFilesChange([localStorageCsvname]);
        setLocalStorageInterval("");
        setFiltersClearToggle(!filtersClearToggle);
        setLocalStorageCsvname("");
        setLocalStorageFromDate("");
      }
    }
  }, [providers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    changeFileProviderStatus();
  }, [changeFileProviderStatus]);

  const selectedProvider = useCallback(() => {
    if (selectedProviders.length === 0) {
      removeAllFilesEngines();
    }
  }, [selectedProviders.length]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    selectedProvider();
  }, [selectedProvider]);

  return {
    clearAllSelection,
    filterValues,
    selectionAddActions,
    filtersClearToggle,
    dataLists,
    removeProviderFromList,
    removeSearchFilesFromList,
    MONTH_TO_DATE,
    localStorageInterval
  };
};
