import React from "react";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import { useSkippedRowsFilters } from "./logic/skippedRowsFilters.logic";
import { useFetchSkippedRows } from "./logic/fetchSkippedRows.logic";
import SkippedRowsFilter from "./layouts/SkippedRowsFilter";
import SkippedRowsTable from "./layouts/SkippedRowsTable";
import SimpleAccordion from "../../components/Accordion/Accordion";
import GetAppIcon from "@material-ui/icons/GetApp";
import CsvRowDetails from "./Details";
const SkippedRowsList = () => {
  const icon = <GetAppIcon fontSize="inherit" color="primary" />;
  const filterValuesLogic = useSkippedRowsFilters();
  const { filterValues, filtersClearToggle } = filterValuesLogic;
  const fetchskippedRowsLogic = useFetchSkippedRows({
    filterValues,
    filtersClearToggle
  });
  const { getskippedRows, uploadStatus, loadingskippedRows } =
    fetchskippedRowsLogic;

  return (
    <div className="publisher-list__container">
      <div className="publisher-list__header">
        <ContentHeader icon={icon} title="Revenue Report Upload Status" />
      </div>
      <div className="flow-content">
        <SimpleAccordion
          header="Filters"
          key="skippedRows-filters"
          defaultExpanded={true}
        >
          <SkippedRowsFilter
            filterValuesLogic={filterValuesLogic}
            handleSearch={getskippedRows}
            loadingskippedRows={loadingskippedRows}
          />
        </SimpleAccordion>
        {uploadStatus && <CsvRowDetails uploadStatus={uploadStatus} />}
        <div className="api-details" style={{ margin: "0px" }}>
          <div className="header">
            <h2>Revenue report status</h2>
          </div>
          <SkippedRowsTable fetchskippedRowsLogic={fetchskippedRowsLogic} />
        </div>
      </div>
    </div>
  );
};

export default SkippedRowsList;
