import { useState, useEffect } from "react";
import { PublisherAccount } from "../../../services/publisherAccounts";

export const useFetchPublisherAccountById = (publisherAccountId) => {
  const [publisherAccount, setPublisherAccount] = useState(null);
  const [loadingPublisherAccount, setLoadingPublisherAccount] = useState({
    isLoading: true,
    error: false
  });

  const fetchPublisherAccountById = async (publisherAccountId) => {
    setLoadingPublisherAccount({
      isLoading: true,
      error: false
    });
    try {
      const response = await PublisherAccount.fetchPublisherById(
        publisherAccountId
      );
      if (response.success) {
        setPublisherAccount(response.data);
        setLoadingPublisherAccount({
          isLoading: false,
          error: false
        });
      } else {
        throw new Error(JSON.stringify(response.error));
      }
    } catch (error) {
      setLoadingPublisherAccount({
        isLoading: false,
        error: true
      });
      setPublisherAccount(null);
    }
  };

  useEffect(() => {
    if (publisherAccountId) {
      fetchPublisherAccountById(publisherAccountId);
    }
  }, [publisherAccountId]);

  return { publisherAccount, loadingPublisherAccount };
};
