export const SettingActionTypes = {
  UPDATE_DEFAULT_SETTINGS: "UPDATE_DEFAULT_SETTINGS",
  UPDATE_SETTINGS_OBJECT: "UPDATE_SETTINGS_OBJECT"
};

export const updateDefaultSettings = (settings) => ({
  type: SettingActionTypes.UPDATE_DEFAULT_SETTINGS,
  payload: settings
});

export const updateSettingsObject = (settingsObject) => ({
  type: SettingActionTypes.UPDATE_SETTINGS_OBJECT,
  payload: settingsObject
});
