import React from "react";
import { TextField } from "@material-ui/core";
import DropDownToolTip from "./DropDownTooltip";
const TooltipTextField = ({ params, label }) => {
  const VALID_VALUE_IN_TEXTFIELD = params?.inputProps?.value;

  return (
    <DropDownToolTip title={VALID_VALUE_IN_TEXTFIELD || ""}>
      <TextField {...params} variant="outlined" label={label} />
    </DropDownToolTip>
  );
};

export default TooltipTextField;
