import { useState } from "react";
import { useTextInput } from "../../../hooks/useTextInput";

export const usePlatformFilters = () => {
  const [query, handleQueryChange, setQuery] = useTextInput({
    initialValue: ""
  });
  const [archivedData, setArchivedData] = useState(false);

  const [filtersClearToggle, setFiltersClearToggle] = useState(false);

  function clearAllSelection() {
    setQuery("");
    setFiltersClearToggle(!filtersClearToggle);
  }

  let filterValues = {
    query,
    archivedData
  };

  let selectionAddActions = {
    handleQueryChange,
    setArchivedData
  };

  return {
    clearAllSelection,
    filterValues,
    selectionAddActions,
    filtersClearToggle
  };
};
