import React from "react";
import SearchEngineForm from "./layouts/SearchEngineForm";
import SearchEngineTable from "./layouts/SearchEngineTable";
import { useFormAndTableSwitch } from "../../hooks/formAndTableSwitch.logic";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
const SearchEngine = () => {
  const { currentPage, openAddForm, openEditForm, openTable } =
    useFormAndTableSwitch();

  return (
    <div>
      {currentPage.page === "form" && (
        <SearchEngineForm
          searchEngineId={
            currentPage.pageData.id ? currentPage.pageData.id : ""
          }
          openTable={openTable}
        />
      )}
      {currentPage.page === "table" && (
        <div
          className="flow-content"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px"
          }}
        >
          <Button
            onClick={openAddForm}
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddBoxIcon />}
          >
            Add New Search Engine
          </Button>
          <SearchEngineTable openEditForm={openEditForm} />
        </div>
      )}
    </div>
  );
};

export default SearchEngine;
