import React from "react";
import RBAC from "../../HOC/Rbac";
import ROLES from "../../routes/Role";
import AdminDashboard from "./dashboard-layouts/admin-dashboard/AdminDashBoard";
import PublisherDashboard from "./dashboard-layouts/publisher-dashboard/PublisherDashboard";
import { RevColHeadersContextProvider } from "../../contexts/revenue-context";
const Home = () => {
  return (
    <div className="dashboard-container">
      <RBAC allowedRoles={ROLES.admin}>
        <AdminDashboard />
      </RBAC>
      <RBAC allowedRoles={ROLES.publisher}>
        <RevColHeadersContextProvider>
          <PublisherDashboard />
        </RevColHeadersContextProvider>
      </RBAC>
    </div>
  );
};

export default Home;
