import { useState, useEffect } from "react";
import { useTextInput } from "../../../hooks/useTextInput";
import useDebounce from "../../../hooks/useDebounce";
export const useFilterAdvertiserMapping = (advertisers) => {
  const [filterText, onFilterTextChange, setFilterText] = useTextInput({
    initialValue: ""
  });
  const debouncedFilterText = useDebounce(filterText, 500);
  const [filteredAdvertisers, setFilteredAdvertisers] = useState(advertisers);

  useEffect(() => {
    if (debouncedFilterText) {
      setFilteredAdvertisers(
        advertisers.filter((advertiser) => {
          let advertiserName = `${advertiser.id}-${advertiser.name}`;
          return advertiserName
            .toLowerCase()
            .includes(debouncedFilterText.toLowerCase());
        })
      );
    } else {
      setFilteredAdvertisers(advertisers);
    }
  }, [debouncedFilterText, advertisers]);

  return {
    filteredAdvertisers,
    filterText,
    onFilterTextChange,
    setFilterText
  };
};
