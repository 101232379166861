import { useState } from "react";
import { useModal } from "../../../../hooks/useModal";
import { findObject } from "../../../../helpers";
export const useRelatedLinkRulesModalLogic = ({ data }) => {
  const [modalOpen, openModal, closeModal] = useModal();
  const [targetObj, setTargetObj] = useState([]);
  const [currentlySelectedID, setCurrentlySelectedID] = useState("");

  function openRelatedLinkModal(selectedID) {
    if (data.length > 0) {
      let selectedLinkObject = findObject(data, selectedID);
      setTargetObj(selectedLinkObject?.targetObj || []);
      setCurrentlySelectedID(selectedID);
      openModal();
    } else {
      closeRelatedLinkModal();
    }
  }

  function closeRelatedLinkModal() {
    setCurrentlySelectedID("");
    setTargetObj([]);
    closeModal();
  }

  return [
    modalOpen,
    openRelatedLinkModal,
    closeRelatedLinkModal,
    targetObj,
    currentlySelectedID
  ];
};
