import React from "react";
import ProviderLinksFilterSelections from "./ProviderLinksFilterSelections";
import ProviderLinksFilterActionButtons from "./ProviderLinksFilterActionButtons";
const ProviderLinksFilter = ({
  filterValuesLogic,
  handleSearch,
  loadingProviderLinks
}) => {
  const { filterValues, selectionAddActions, clearAllSelection, dataLists } =
    filterValuesLogic;
  return (
    <div className="flow-content providerLinks-filter__container">
      <ProviderLinksFilterSelections
        selectionAddActions={selectionAddActions}
        filterValues={filterValues}
        handleSearch={handleSearch}
        dataLists={dataLists}
      />
      <ProviderLinksFilterActionButtons
        handleSearch={handleSearch}
        clearAllSelection={clearAllSelection}
        loadingData={loadingProviderLinks.isLoading}
      />
    </div>
  );
};

export default ProviderLinksFilter;
