export const sortProviderLinks = (links) => {
  /*  Sorts providerlinks by id in ascending order
   */
  let output = [...links];
  output = output.sort((a, b) => a.id - b.id);
  return output;
};

export const getUniqueRelatedRules = (array) => {
  //Removes Rules with the same tag_id and rule_id
  const filteredArr = array.reduce((acc, current) => {
    const x = acc.find(
      (item) =>
        item.tag_id === current.tag_id && item.rule_id === current.rule_id
    );
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  return filteredArr;
};

export const sortRelatedRules = (rules) => {
  // Sorts related rules by tag_id in ascending order
  let output = [...rules];
  output = output.sort((a, b) => a.tag_id - b.tag_id);
  return output;
};

export const clearLinkErrorValues = (lengthOfErrorArray) => {
  const formErrors = [];
  for (let i = 0; i < lengthOfErrorArray; i++) {
    formErrors.push({
      link: false,
      search_engine_type: false,
      p_sid: false
    });
  }
  return formErrors;
};

export const getActiveRules = (rules) => {
  let activeRules = [];
  rules.forEach((rule) => {
    if (!rule.disabled) {
      // rule is active
      activeRules.push(rule.rule_id);
    }
  });
  let uniqueActiveRules = [...new Set(activeRules)];
  return uniqueActiveRules;
};

export const generateMappedLink = (
  link
  // searchq_val,
  // n_val,
  // sub_id_val,
  // click_id_val
) => {
  if (!link || link.trim() === "") {
    return;
  }
  // let lengthOfLink = link.length;
  // if (link[lengthOfLink - 1] === "&") {
  //   link = link.replace("&", "");
  // }
  // if (link[lengthOfLink - 1] === "?") {
  //   link = link.replace("?", "");
  // }
  // if (!searchq_val && !n_val && !sub_id_val && !click_id_val) {
  //   return link;
  // }

  // if (searchq_val) {
  //   if (
  //     !link.includes(`&${searchq_val}=`) &&
  //     !link.includes(`?${searchq_val}=`)
  //   ) {
  //     if (searchq_val.trim()) {
  //       if (!link.includes("?")) link = link + "?";
  //       else link = link + "&";
  //       link = `${link}${searchq_val}=[Q]`;
  //     }
  //   }
  // }

  // if (n_val) {
  //   if (!link.includes(`&${n_val}=`) && !link.includes(`?${n_val}=`)) {
  //     if (n_val.trim()) {
  //       if (!link.includes("?")) link = link + "?";
  //       else link = link + "&";
  //       link = `${link}${n_val}=[N]`;
  //     }
  //   }
  // }

  // if (sub_id_val) {
  //   if (
  //     !link.includes(`&${sub_id_val}=`) &&
  //     !link.includes(`?${sub_id_val}=`)
  //   ) {
  //     if (sub_id_val.trim()) {
  //       if (!link.includes("?")) link = link + "?";
  //       else link = link + "&";
  //       link = `${link}${sub_id_val}=[SID]`;
  //     }
  //   }
  // }
  // if (click_id_val) {
  //   if (
  //     !link.includes(`&${click_id_val}`) &&
  //     !link.includes(`?${click_id_val}`)
  //   ) {
  //     if (click_id_val.trim()) {
  //       if (!link.includes("?")) link = link + "?";
  //       else link = link + "&";
  //       link = `${link}${click_id_val}=[CLICK_ID]`;
  //     }
  //   }
  // }

  // if (link.includes("&&")) {
  //   link = link.replace("&&", "&");
  // }
  // if (link.includes("??")) {
  //   link = link.replace("??", "?");
  // }
  // if (link.includes(".com?")) {
  //   link = link.replace(".com?", ".com/?");
  // }

  return link;
};
