import React, { useEffect, useState, useRef, useCallback } from "react";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import { textFieldValid } from "../../../helpers/validation";
import FormControl from "@material-ui/core/FormControl";
import { toastr } from "react-redux-toastr";
import { capitalizeFirstLetter } from "../../../helpers";

const EditableCell = ({ header, data, handleCellValueUpdate }) => {
  const prevValue = data?.[header] ? data?.[header].toString() : "";
  const [showEdit, setShowEdit] = useState(false);
  const [value, setValue] = useState(prevValue);
  const [error, setError] = useState(false); //form field error
  const node = useRef();
  const handleClose = useCallback(() => {
    setShowEdit(false);
    if (!textFieldValid(value)) {
      setValue(prevValue);
      toastr.warning("Oops", "Empty value not saved!");
      return;
    }
    if (value !== prevValue) {
      handleCellValueUpdate(data, header, value);
    }
  }, [data, header, value, handleCellValueUpdate, prevValue]);

  const handleClick = useCallback(
    (e) => {
      if (node.current.contains(e.target)) {
        //inside cell click
        return;
      }
      //outside click
      if (showEdit) {
        handleClose();
      }
    },
    [handleClose, showEdit]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [showEdit, data, header, handleClick]);

  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      setShowEdit(false);
      setValue(prevValue);
      return;
    }
    if (e.key === "Enter") {
      if (!textFieldValid(value)) {
        setError(true);
        return;
      }
      if (value !== prevValue) {
        handleCellValueUpdate(data, header, value);
      }
      setShowEdit(false);
    }
  };

  const editSection = (
    <FormControl fullWidth>
      <TextField
        variant="standard"
        margin="normal"
        label={capitalizeFirstLetter(header)}
        name="editField"
        value={value}
        onChange={(e) => {
          setError(false);
          setValue(e.target.value);
        }}
        onKeyUp={handleKeyPress}
        autoFocus
        placeholder="Enter value"
        error={error}
        fullWidth
      />
    </FormControl>
  );

  return (
    <TableCell
      key={`edit+${header}`}
      onClick={() => setShowEdit(true)}
      ref={node}
      className="editableCell"
    >
      <div>
        {showEdit ? (
          <div className="editableCell__editSection">{editSection}</div>
        ) : (
          <div className="editableCell__showSection">{prevValue}</div>
        )}
      </div>
    </TableCell>
  );
};

export default EditableCell;
