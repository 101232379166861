import UserActionTypes from "./user.types";

const INITIAL_STATE = {
  error: null,
   isFetchingUser: false,
   isFetchingUsersList: false,
  users: []
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.FETCH_USER_START:
      return {
        ...state,
         isFetchingUser: true
      };
    case UserActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
         isFetchingUser: false
      };
    case UserActionTypes.FETCH_USER_FAILURE:
      return {
        ...state,
         isFetchingUser: false,
        error: action.payload
      };
    case UserActionTypes.ADD_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload
      };
    case UserActionTypes.FETCH_USERS_LIST_START:
      return {
        ...state,
         isFetchingUsersList: true
      };
    case UserActionTypes.FETCH_USERS_LIST_SUCCESS:
      return {
        ...state,
         isFetchingUsersList: false
      };
    case UserActionTypes.FETCH_USERS_LIST_FAILURE:
      return {
        ...state,
         isFetchingUsersList: false,
        error: action.payload
      };
    case UserActionTypes.UPDATE_USERS_LIST:
      return {
        ...state,
        users: action.payload
      };
    default:
      return { ...state };
  }
};

export default userReducer;
