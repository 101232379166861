import React from "react";
import { Link } from "react-router-dom";

const DisplayNamesInfoMsg = () => (
  <div className="message-box">
    Display Names not set yet. <br />
    Please enter display names before setting csv fields for advertiser.
    <br />
    <Link to="/displaynames">Open CSV Field Mapping Page.</Link>
  </div>
);

export default DisplayNamesInfoMsg;
