import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { useSubmitTagTypeLogic } from "./submitTagType.logic";
import { useFormTouched } from "../../../hooks/useFormTouched";
import { changeEmptyStringToNull } from "../../../helpers";
import { TagType } from "../../../services/TagType";
const initialFormValues = {
  name: "",
  details: "",
  status: true
};

const initialErrorValues = {
  name: false,
  details: false
};

export const useTagTypeFormLogic = (tagTypeID, openTable) => {
  const [formValues, setFormValues] = useState(() => initialFormValues);
  const [errorValues, setErrorValues] = useState(() => initialErrorValues);

  const { isSubmitting, saveTagType, updateTagType } = useSubmitTagTypeLogic();

  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction: () => {}
  });

  const [editing, setIsEditing] = useState({
    id: "",
    isEditing: false
  });

  const [loadingTagTypeData, setLoadingTagTypeData] = useState({
    isLoading: false,
    error: false
  });

  const isMounted = useIsMounted();

  async function getTagType(tagTypeId) {
    try {
      setLoadingTagTypeData({ isLoading: true, error: false });
      const tagType = await TagType.fetchTagType(tagTypeId);
      if (tagType.success && isMounted()) {
        setLoadingTagTypeData({ isLoading: false, error: false });
        setFormValues({
          name: tagType.data.tagType.name || "",
          details: tagType.data.tagType.details || "",
          status: tagType.data.tagType.status || true
        });
        setIsEditing({
          id: tagType.data.tagType.id,
          isEditing: true
        });
      } else {
        throw new Error(tagType.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setLoadingTagTypeData({ isLoading: false, error: true });
        console.trace(error.message);
        toastr.warning("Oops!", error.message);
      }
    }
  }

  const handleFormValueChange = (e) => {
    handleFormTouched();
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    setErrorValues({
      ...errorValues,
      [name]: false
    });
  };

  const handleStatusChange = () => {
    handleFormTouched();
    setFormValues({
      ...formValues,
      status: !formValues.status
    });
  };

  const handleAddTagType = async (dataToSend) => {
    const savedTagType = await saveTagType(dataToSend);
    if (savedTagType.success) {
      toastr.success("Success!", savedTagType.msg);
      setIsEditing({
        id: savedTagType.data.id,
        isEditing: true
      });
      clearFormTouched();
      openTable();
    } else {
      toastr.error("Oops!", savedTagType.msg);
    }
  };

  const handleUpdateTagType = async (dataToSend) => {
    const updatedTagType = await updateTagType(editing.id, dataToSend);
    if (updatedTagType.success) {
      toastr.success("Success!", updatedTagType.msg);
      clearFormTouched();
      openTable();
    } else {
      toastr.error("Oops!", updatedTagType.msg);
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!changeEmptyStringToNull(formValues.name)) {
      setErrorValues({
        ...errorValues,
        name: true
      });
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    let dataToSend = {
      name: formValues.name.trim(),
      details: changeEmptyStringToNull(formValues.details),
      status: formValues.status
    };
    if (editing.isEditing) {
      handleUpdateTagType(dataToSend);
    } else {
      handleAddTagType(dataToSend);
    }
  };

  const handleCancel = () => {
    clearFormTouched();
    openTable();
  };

  useState(() => {
    if (tagTypeID) {
      getTagType(tagTypeID);
    }
  }, [tagTypeID]);

  return {
    formValues,
    errorValues,
    loadingTagTypeData,
    handleFormValueChange,
    handleStatusChange,
    handleSubmit,
    handleCancel,
    isSubmitting,
    editing,
    formTouched
  };
};
