import React, { useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useLocation } from "react-router-dom";

const DashboardNavs = ({ handleLogout, currentUser }) => {
  const [open, setOpen] = React.useState(false);

  const { pathname } = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(false);
  };

  //closing the sidebar when route changes
  //and loading settings if not already loaded
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <>
      <Header
        handleDrawerOpen={handleDrawerOpen}
        logout={handleLogout}
        user={currentUser}
      />
      <Sidebar
        handleDrawerClose={handleDrawerClose}
        open={open}
        handleLogout={handleLogout}
      />
    </>
  );
};

export default DashboardNavs;
