import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import moment from "moment";
import "./SimpleDateRangePicker.scss";

const SimpleDateRangePicker = ({
  startDate,
  endDate,
  minDate,
  handleDateChange
}) => {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <DateRangePicker
      startDateId="startDate"
      endDateId="endDate"
      startDate={startDate}
      endDate={endDate}
      onDatesChange={({ startDate, endDate }) => {
        handleDateChange(startDate, endDate);
      }}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => {
        setFocusedInput(focusedInput);
      }}
      minDate={
        minDate ? moment(new Date(minDate)) : moment(new Date("1900-01-01"))
      }
      maxDate={moment(new Date())}
      isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
      initialVisibleMonth={() =>
        startDate ? moment(startDate) : moment().subtract(1, "month")
      }
      startDatePlaceholderText="From"
      endDatePlaceholderText="To"
      readOnly={true}
      minimumNights={0}
      showClearDates={true}
    />
  );
};

export default SimpleDateRangePicker;
