import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { useSubmitSearchEngineLogic } from "./submitSearchEngine.logic";
import { useFormTouched } from "../../../hooks/useFormTouched";
import { changeEmptyStringToNull } from "../../../helpers";
import { SearchEngine } from "../../../services/SearchEngine";
const initialFormValues = {
  name: "",
  details: "",
  status: true
};

const initialErrorValues = {
  name: false,
  details: false
};

export const useSearchEngineFormLogic = (searchEngineID, openTable) => {
  const [formValues, setFormValues] = useState(() => initialFormValues);
  const [errorValues, setErrorValues] = useState(() => initialErrorValues);

  const { isSubmitting, saveSearchEngine, updateSearchEngine } =
    useSubmitSearchEngineLogic();

  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction: () => {}
  });

  const [editing, setIsEditing] = useState({
    id: "",
    isEditing: false
  });

  const [loadingSearchEngineData, setLoadingSearchEngineData] = useState({
    isLoading: false,
    error: false
  });

  const isMounted = useIsMounted();

  async function getSearchEngine(searchEngineId) {
    try {
      setLoadingSearchEngineData({ isLoading: true, error: false });
      const searchEngine = await SearchEngine.fetchSearchEngine(searchEngineId);
      if (searchEngine.success && isMounted()) {
        setLoadingSearchEngineData({ isLoading: false, error: false });
        setFormValues({
          name: searchEngine.data.searchEngine.name || "",
          details: searchEngine.data.searchEngine.details || "",
          status: searchEngine.data.searchEngine.status || true
        });
        setIsEditing({
          id: searchEngine.data.searchEngine.id,
          isEditing: true
        });
      } else {
        throw new Error(searchEngine.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setLoadingSearchEngineData({ isLoading: false, error: true });
        console.trace(error.message);
        toastr.warning("Oops!", error.message);
      }
    }
  }

  const handleFormValueChange = (e) => {
    handleFormTouched();
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    setErrorValues({
      ...errorValues,
      [name]: false
    });
  };

  const handleStatusChange = () => {
    handleFormTouched();
    setFormValues({
      ...formValues,
      status: !formValues.status
    });
  };

  const handleAddSearchEngine = async (dataToSend) => {
    const savedSearchEngine = await saveSearchEngine(dataToSend);
    if (savedSearchEngine.success) {
      toastr.success("Success!", savedSearchEngine.msg);
      setIsEditing({
        id: savedSearchEngine.data.id,
        isEditing: true
      });
      clearFormTouched();
      openTable();
    } else {
      toastr.error("Oops!", savedSearchEngine.msg);
    }
  };

  const handleUpdateSearchEngine = async (dataToSend) => {
    const updatedSearchEngine = await updateSearchEngine(
      editing.id,
      dataToSend
    );
    if (updatedSearchEngine.success) {
      toastr.success("Success!", updatedSearchEngine.msg);
      clearFormTouched();
      openTable();
    } else {
      toastr.error("Oops!", updatedSearchEngine.msg);
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!changeEmptyStringToNull(formValues.name)) {
      setErrorValues({
        ...errorValues,
        name: true
      });
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    let dataToSend = {
      name: formValues.name.trim(),
      details: changeEmptyStringToNull(formValues.details),
      status: formValues.status
    };
    if (editing.isEditing) {
      handleUpdateSearchEngine(dataToSend);
    } else {
      handleAddSearchEngine(dataToSend);
    }
  };

  const handleCancel = () => {
    clearFormTouched();
    openTable();
  };

  useState(() => {
    if (searchEngineID) {
      getSearchEngine(searchEngineID);
    }
  }, [searchEngineID]);

  return {
    formValues,
    errorValues,
    loadingSearchEngineData,
    handleFormValueChange,
    handleStatusChange,
    handleSubmit,
    handleCancel,
    isSubmitting,
    editing,
    formTouched
  };
};
