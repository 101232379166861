import React from "react";
import PlatformFilterSelections from "./PlatformFilterSelections";
import PlatformFilterActionButtons from "./PlatformFilterActionButtons";
const PlatformTableFilter = ({
  clearAllSelection,
  handleSearch,
  filterValuesLogic,
  loadingPlatforms
}) => {
  const { selectionAddActions, filterValues } = filterValuesLogic;

  return (
    <div>
      <PlatformFilterSelections
        selectionAddActions={selectionAddActions}
        filterValues={filterValues}
        handleSearch={handleSearch}
      />
      <PlatformFilterActionButtons
        handleSearch={handleSearch}
        clearAllSelection={clearAllSelection}
        loadingData={loadingPlatforms.isLoading}
      />
    </div>
  );
};

export default PlatformTableFilter;
