import React from "react";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import { useGetAdvertiserWithMapping } from "./getAdvertiserWithMapping.logic";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { CsvContextProvider } from "../../../contexts/csv-context";
import AdvertiserListBoxContainer from "./Layouts/AdvertiserListBoxContainer";
const CsvUpload = () => {
  const icon = <AddToPhotosIcon fontSize="inherit" color="primary" />;

  const { advertisers, loadingAdvertisers } = useGetAdvertiserWithMapping();

  return (
    <div>
      <ContentHeader icon={icon} title="Upload CSV Reports" />
      {loadingAdvertisers && <div>Loading..</div>}
      {!loadingAdvertisers && advertisers.length > 0 && (
        <CsvContextProvider>
          <AdvertiserListBoxContainer advertisers={advertisers} />
        </CsvContextProvider>
      )}
      {!loadingAdvertisers && advertisers.length === 0 && (
        <div>No data about advertisers</div>
      )}
    </div>
  );
};

export default CsvUpload;
