import * as React from "react";

const CsvContext = React.createContext();

function csvReducer(state, action) {
  switch (action.type) {
    case "add polling data entry":
      return {
        ...state,
        pollingData: state.pollingData.concat(action.payload)
      };
    case "remove polling data entry":
      return {
        ...state,
        pollingData: state.pollingData.filter(
          (entry) => entry.advertiser_id !== action.payload.advertiser_id
        )
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function CsvContextProvider(props) {
  const [state, dispatch] = React.useReducer(csvReducer, {
    pollingData: []
  });
  const value = [state, dispatch];
  return <CsvContext.Provider value={value} {...props} />;
}

export function useCsvContext() {
  const context = React.useContext(CsvContext);
  if (context === undefined) {
    throw new Error("useCsvContext must be used within a CsvContextProvider");
  }
  return context;
}

export const addPollingDataEntry = (dispatch, entry) => {
  dispatch({ type: "add polling data entry", payload: entry });
};

export const removePollingDataEntry = (dispatch, entry) => {
  dispatch({ type: "remove polling data entry", payload: entry });
};

export const getPollingDataEntry = (state, id) => {
  const entry = state.pollingData.find((entry) => entry.advertiser_id === id);
  return entry;
};
