import React, { useState, useEffect } from "react";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import SelectMenu from "../../../components/SelectMenu/SelectMenu";
import { Publisher } from "../../../services/Publisher";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import LinkInfoForm from "./LinkInfoForm";

const PublisherLinks = () => {
  const icon = <PostAddIcon fontSize="inherit" color="primary" />;
  const [publishers, setPublishers] = useState(null);
  const [publisher, setPublisher] = useState("none");

  const handlePublisherChange = (event) => {
    setPublisher(event.target.value);
  };
  const fetchPublishers = () => {
    Publisher.fetchPublishers()
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          for (let p of data) {
            publishers.push({ label: p.name, value: p.id });
          }
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  };
  useEffect(() => {
    fetchPublishers();
  }, []);

  return (
    <div className="publisher-links-manage">
      <ContentHeader icon={icon} title="Publisher Links" />
      <div className="publisher-links-manage__section">
        <div className="publisher-links-manage__section-top">
          <div className="publisher-links-manage__section-top__input">
            <SelectMenu
              selected={publisher}
              data={publishers}
              handleChange={handlePublisherChange}
              label={"Publisher"}
            />
          </div>
          <div className="publisher-links-manage__section-top__input">
            <FormControl fullWidth>
              <TextField
                variant="standard"
                margin="normal"
                label="Publisher ID (PID)"
                name="pid"
                placeholder="Publisher ID (PID)"
                value={321}
                disabled
              />
            </FormControl>
          </div>
          <div className="publisher-links-manage__section-top__input">
            <FormControl fullWidth>
              <TextField
                variant="standard"
                margin="normal"
                label="Status"
                name="status"
                placeholder="Status"
                value="Active"
                disabled
              />
            </FormControl>
          </div>
        </div>
        <div className="publisher-links-manage__section-medium">
          <LinkInfoForm />
        </div>
        <div className="publisher-links-manage__section-bottom">
          <div className="form__controls">
            <Button
              color="primary"
              variant="contained"
              size="medium"
              startIcon={<CheckIcon />}
              type="submit"
            >
              {" "}
              Save
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              startIcon={<ClearIcon />}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PublisherLinks;
