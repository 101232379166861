import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import Form from "./Form";
import { LinkedAccount } from "../../services/LinkedAccount/index";
import { toastr } from "react-redux-toastr";

const icon = <PostAddIcon fontSize="inherit" color="primary" />;

const LinkedAccounts = () => {
  let { id } = useParams();
  const [linkedAccountData, setLinkedAccountData] = useState(false);
  const history = useHistory();

  useEffect(() => {
    LinkedAccount.fetchLinkedAccount(id).then(({ data }) =>
      setLinkedAccountData(data)
    );
  }, [id]);

  const onSubmit = (data) => {
    LinkedAccount.updateLinkedAccount(id, data).then((response) => {
      if (response.success) {
        history.push("/pubaccount");
        toastr.success("Success", `Publisher Ad Account ID: ${id} saved`);
      } else {
        throw new Error(JSON.stringify(response.error));
      }
    });
  };

  const onCancel = () => history.push("/pubaccount");

  return (
    <div className="linked-account">
      <ContentHeader icon={icon} title="Publisher ad Account" />
      <div className="content_box">
        <fieldset className="custom-fieldset">
          <legend>Edit Linked Account</legend>
          {linkedAccountData && (
            <Form
              initialData={linkedAccountData}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          )}
        </fieldset>
      </div>
    </div>
  );
};

export default LinkedAccounts;
