import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { useModal } from "../../../hooks/useModal";
import { Platform } from "../../../services/Platform";

export const usePlatformTablelogic = ({
  filterValues,
  getPlatforms,
  openEditForm
}) => {
  const [archiveModalOpen, openArchiveModal, closeArchiveModal] =
    useModal(false);
  const [archiveId, setArchiveId] = useState(null);

  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal(false);
  const [deleteId, setDeleteId] = useState(null);

  const [restoreModalOpen, openRestoreModal, closeRestoreModal] =
    useModal(false);
  const [restoreId, setRestoreId] = useState(null);

  function handleEditAction(id) {
    openEditForm(id);
  }

  function handleArchiveAction(id) {
    setArchiveId(id);
    openArchiveModal();
  }

  async function archivePlatform() {
    try {
      const archivedPlatform = await Platform.deletePlatform(archiveId);
      if (archivedPlatform.success) {
        toastr.success("Success", "Platform has been archived");
        getPlatforms();
        handleModalClose();
      } else {
        throw new Error(JSON.stringify(archivedPlatform.error));
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", "Not able to remove at the moment");
      handleModalClose();
    }
  }

  async function deletePlatform() {
    try {
      const params = {
        permanent: true
      };
      const deletedPlatform = await Platform.deletePlatform(deleteId, params);
      if (deletedPlatform.success) {
        toastr.success("Platform Deleted", "Platform has been deleted");
        getPlatforms();
        handleModalClose();
      } else {
        throw new Error(JSON.stringify(deletedPlatform.error));
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", "Not able to remove at the moment");
      handleModalClose();
    }
  }

  async function restorePlatform() {
    try {
      const params = {
        archive: false
      };
      const dataToSend = {};
      const restoredPlatform = await Platform.updatePlatform(
        restoreId,
        dataToSend,
        params
      );
      if (restoredPlatform.success) {
        toastr.success("Platform", "Platform restored successfully");
        getPlatforms();
        handleModalClose();
      } else {
        throw new Error(restoredPlatform.error);
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops", "Not able to restore platform");
      handleModalClose();
    }
  }

  function handlePermanaentDeleteAction(id) {
    setDeleteId(id);
    openDeleteModal();
  }

  function handleRestoreAction(id) {
    setRestoreId(id);
    openRestoreModal();
  }

  const handleModalClose = () => {
    closeArchiveModal();
    closeDeleteModal();
    closeRestoreModal();
    setArchiveId(null);
    setDeleteId(null);
    setRestoreId(null);
  };

  const headers = [
    { title: "ID", value: "id" },
    { title: "Name", value: "name" },
    { title: "Details", value: "details" }
  ];

  let actions = [];
  if (!filterValues.archivedData) {
    actions = [
      {
        name: "edit",
        action: handleEditAction,
        icon: "editIcon"
      }
      // {
      //   name: "delete",
      //   action: handleArchiveAction,
      //   icon: "deleteIcon"
      // }
    ];
  } else {
    actions = [
      {
        name: "deleteForever",
        action: handlePermanaentDeleteAction,
        icon: "deleteForever"
      },
      {
        name: "restore",
        action: handleRestoreAction,
        icon: "restore"
      }
    ];
  }

  return {
    headers,
    actions,
    archiveModalOpen,
    deleteModalOpen,
    restoreModalOpen,
    handleModalClose,
    archivePlatform,
    deletePlatform,
    restorePlatform,
    handleArchiveAction
  };
};
