import React from "react";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";

const ActionButton = ({ isExist, setIsExist }) => {
  const handleClick = (e) => {
    e.preventDefault();
    setIsExist(!isExist);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleClick}
        startIcon={<AddBoxIcon />}
      >
        Add Share Revenue(%)
      </Button>
    </div>
  );
};

export default ActionButton;
