import React, { useState, useEffect } from "react";
import ProviderIcon from "@material-ui/icons/CardTravelSharp";
import { useParams } from "react-router-dom";

import TabMenu from "../../components/TabMenu/TabMenu";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import Manage from "../Provider/Manage";
import EditUpdate from "./layout/EditUpdate";
import FieldMapping from "../mapping/CsvFieldMapping/FieldMapping";
import { useLocation } from "react-router-dom";
const ProviderApiInfo = () => {
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] =
    useState(0);
  const { id } = useParams() || null;
  const location = useLocation();
  const type = location.state?.type || null;


  const sectionBuilder = () => {
    const sections = [
      {
        name: "Main",
        component: <Manage />
      }
    ];
    if (id) {
      sections.push({
        name: "Api Credentials",
        component: <EditUpdate data={{ provider_id: id }} />
      });
    }
    if (id) {
      sections.push({
        name: "Map CSV Fields",
        component: (
          <FieldMapping
            data={{
              provider_id: id,
              setCurrentSelectedSectionIndex: setCurrentSelectedSectionIndex
            }}
          />
        )
      });
    }
    return sections;
  };

  useEffect(() => {
    if (type) {
     // setCurrentSelectedSectionIndex(2);
      return () => {
        setCurrentSelectedSectionIndex(0);
      };
    }
  }, [type]);


  const sections = sectionBuilder();

  const icon = <ProviderIcon fontSize="inherit" color="primary" />;

  return (
    <div className="api_details-container">
      <ContentHeader icon={icon} title="Manage Advertiser" />

      <TabMenu
        sections={sections}
        setCurrentSelectedSectionIndex={setCurrentSelectedSectionIndex}
        currentSelectedSectionIndex={currentSelectedSectionIndex}
      />
      <div className="settings__section">
        {sections[currentSelectedSectionIndex].component}
      </div>
    </div>
  );
};

export default ProviderApiInfo;
