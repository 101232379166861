import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { validate } from "../../../helpers/validation";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/CloseSharp";
import ProfilePhotoUpdate from "./ProfilePhotoUpdate";
import CheckIcon from "@material-ui/icons/Check";
import { changeEmptyStringToNull } from "../../../helpers/index";
import { User } from "../../../services/User";
import { toastr } from "react-redux-toastr";
import ProfilePicture from "../../../components/ProfilePicture/ProfilePicture";

const ProfileBasicInfo = ({
  handleCancel,
  profile,
  currentUser,
  refreshProfileInfo
}) => {
  let userID = currentUser?.id ? currentUser?.id : "";
  let userRoleID = currentUser?.role?.id || "";
  const [formValues, setFormValues] = useState({
    firstname: {
      value: profile.first_name,
      error: false,
      label: "First Name",
      type: "text",
      errorMsg: "Please enter valid first name!",
      validOptions: ["isRequired"]
    },
    lastname: {
      value: profile.last_name,
      error: false,
      label: "Last Name",
      type: "text",
      errorMsg: "Please enter valid last name",
      validOptions: ["isRequired"]
    },
    email: {
      value: profile.email,
      error: false,
      label: "Email",
      type: "email",
      errorMsg: "Please enter valid Email",
      validOptions: ["isRequired", "validEmail"]
    },
    contactnumber: {
      value: profile.contact_number,
      error: false,
      label: "Contact Number",
      type: "text",
      errorMsg: "Please enter valid contact number",
      validOptions: []
    },
    skype: {
      value: profile.skype_details,
      error: false,
      label: "Skype Details",
      type: "text",
      errorMsg: "Please enter valid Skype details",
      validOptions: []
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm() || !userID) {
      return;
    }
    let formData = {
      first_name: formValues.firstname.value.trim(),
      last_name: formValues.lastname.value.trim(),
      email: formValues.email.value.trim(),
      // username:profile.username,
      role_id: profile.role_id,
      contact_number: changeEmptyStringToNull(formValues.contactnumber.value),
      skype_details: changeEmptyStringToNull(formValues.skype.value)
    };

    User.updateProfile(userID, formData)
      .then((response) => {
        if (response.success) {
          refreshProfileInfo();
          toastr.success("Success", "Profile updated successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops", JSON.parse(error.message).message);
      });
  };

  const validateForm = () => {
    let isValid = true;
    let tempFormValues = { ...formValues };
    Object.keys(formValues).forEach((key) => {
      formValues[key].validOptions.forEach((option) => {
        if (!validate(option, formValues[key].value)) {
          tempFormValues = {
            ...tempFormValues,
            [key]: {
              ...formValues[key],
              error: true
            }
          };
          isValid = false;
        }
      });
    });
    setFormValues(tempFormValues);
    return isValid;
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: {
        ...formValues[e.target.name],
        value: e.target.value,
        error: false
      }
    });
  };

  return (
    <div className="profile__basic-info">
      <div className="profile__basic-info__title">
        <h4>Basic Info</h4>
        <div>
          Basic info such as your name, email, photo and contact number.
        </div>
      </div>
      <div className="profile__basic-info__content flow-content">
        <div className="profile__basic-info__form">
          <h5>Change info</h5>
          <form onSubmit={handleSubmit}>
            {Object.keys(formValues).map((key) => {
              return (
                <div key={key} className="profile__basic-info__form__field">
                  <FormControl fullWidth>
                    {" "}
                    <TextField
                      variant="outlined"
                      label={formValues[key].label}
                      name={key}
                      value={formValues[key].value}
                      error={formValues[key].error}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      autoFocus
                      placeholder={`Enter ${formValues[key].label}`}
                      type={formValues[key].type}
                    />
                    {formValues[key].error && (
                      <FormHelperText>
                        {formValues[key].errorMsg}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              );
            })}
            <div className="profile__basic-info__form__buttons">
              <Button
                color="primary"
                variant="contained"
                size="medium"
                startIcon={<CheckIcon />}
                type="submit"
              >
                {" "}
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
        <div className="profile__basic-info__photo-preview">
          <ProfilePicture imageLink={profile.profile_link} />
        </div>
        <ProfilePhotoUpdate
          userID={userID}
          refreshProfileInfo={refreshProfileInfo}
          userRoleID={userRoleID}
        />
      </div>
    </div>
  );
};

export default ProfileBasicInfo;
