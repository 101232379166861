import SkippedRowsActionTypes from "./skippedRows.types";

const INITIAL_STATE = {
  error: null,
  isFetching: false,
  isFetchingsList: false,
  skippedRows: []
};

const skippedRowsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SkippedRowsActionTypes.FETCH_START:
      return {
        ...state,
        isFetching: true
      };
    case SkippedRowsActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case SkippedRowsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case SkippedRowsActionTypes.ADD_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload
      };
    case SkippedRowsActionTypes.FETCH_LIST_START:
      return {
        ...state,
        isFetchingsList: true
      };
    case SkippedRowsActionTypes.FETCH_LIST_SUCCESS:
      return {
        ...state,
        isFetchingsList: false
      };
    case SkippedRowsActionTypes.FETCH_LIST_FAILURE:
      return {
        ...state,
        isFetchingsList: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export default skippedRowsReducer;
