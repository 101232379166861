import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { useRedirectLogic } from "../../../../hooks/useRedirectLogic";

const UploadSuccessBox = ({ message, uploadAnotherFile, uploadData }) => {
  const { redirectToSkippedRows } = useRedirectLogic();
  return (
    <div className="csv-upload__success dotted-box">
      <span className="upload-text">
        {message}
        <Button
          size="small"
          color="inherit"
          className="csv-upload-status-btn"
          onClick={() => {
            redirectToSkippedRows(
              +uploadData?.advertiser_id,
              uploadData.csv_name
            );
          }}
        >
          csv upload status
        </Button>
      </span>
      <Button
        size="small"
        className="csv-upload__upload-btn"
        color="inherit"
        onClick={uploadAnotherFile}
      >
        Upload Another File
      </Button>
    </div>
  );
};

UploadSuccessBox.propTypes = {
  message: PropTypes.string.isRequired,
  uploadAnotherFile: PropTypes.func.isRequired
};

export default UploadSuccessBox;
