import { useState } from "react";
import { Provider } from "../../services/Provider";
import { toastr } from "react-redux-toastr";

export const useVerifyingProviderLinks = () => {
  const [currentlyVerifying, setCurrentlyVerifying] = useState(false);
  const [frontEndDuplicates, setFrontEndDuplicates] = useState({
    hasDuplicatesInForm: false,
    duplicateLinkIdsInForm: []
  });

  const [backEndDuplicates, setBackEndDuplicates] = useState({
    hasDuplicatesInBackend: false,
    duplicateLinkIdsInBackend: []
  });

  /**
   *  Input : Links Array Object
   *  Output : Object with Link url as Key and 0 as intial count
   */
  function getLinkKeyObject(links) {
    let output = {};

    function getOutputIdsArray(linkObject, output_ids) {
      if (linkObject?.id) {
        output_ids.push(linkObject.id);
      }
      return output_ids;
    }
    links.forEach((linkObject) => {
      if (linkObject.link in output) {
        let output_ids = [...output[linkObject.link].ids];
        let output_count = output[linkObject.link].count;
        output[linkObject.link] = {
          ids: getOutputIdsArray(linkObject, output_ids),
          count: output_count + 1
        };
      } else {
        let output_ids = [];
        output[linkObject.link] = {
          ids: getOutputIdsArray(linkObject, output_ids),
          count: 1
        };
      }
    });
    return output;
  }

  /**
   * Input: Links from the form
   * Output: Number of Identical Links,
   * Process:
   *  -Get Object where link url is a key and number of occurences is a count, linkObject
   *  -loop through linkobject, if count > 1 , increase no of duplicate links and add id to array
   *  -Return noOfDuplicate and identical id in an object
   */
  function validateForIdenticalLinks(formData) {
    let noOfDuplicateLinks = 0;
    let duplicatedLinkIds = [];
    let linkObject = getLinkKeyObject(formData);
    Object.keys(linkObject).forEach((key) => {
      if (linkObject[key].count > 1) {
        noOfDuplicateLinks = noOfDuplicateLinks + 1;
        linkObject[key].ids.forEach((id) => {
          if (!duplicatedLinkIds.includes(id)) {
            duplicatedLinkIds.push(id);
          }
        });
      }
    });
    return { noOfDuplicateLinks, duplicatedLinkIds };
  }

  function getArrayOfLinks(links) {
    let output = [];
    links.forEach((linkObject) => {
      output.push(linkObject.link);
    });
    return output;
  }

  async function fetchBackEndIdenticalLinks(providerId, formData) {
    let data = getArrayOfLinks(formData);
    let hasDuplicatesInBackend = false;
    const duplicateLinkIdsInBackend = [];
    try {
      const identicalLinks = await Provider.getIdenticalLinks(providerId, {
        data
      });
      if (identicalLinks.success) {
        if (identicalLinks.data.is_Found_Identical) {
          hasDuplicatesInBackend = true;
          const duplicateLinks = identicalLinks.data.links;
          duplicateLinks.forEach((linkArray) => {
            let link = linkArray[0];
            duplicateLinkIdsInBackend.push({
              linkId: link.id,
              providerId: link.provider.id,
              providerName: link.provider.name
            });
          });
        }
      } else {
        throw new Error(JSON.stringify(identicalLinks.error));
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", error.message);
    }
    return { hasDuplicatesInBackend, duplicateLinkIdsInBackend };
  }

  function checkFrontEndIdenticalLinks(formData) {
    const { noOfDuplicateLinks, duplicatedLinkIds } =
      validateForIdenticalLinks(formData);
    const hasDuplicatesInForm = noOfDuplicateLinks > 0;
    const duplicateLinkIdsInForm = duplicatedLinkIds;
    setFrontEndDuplicates({
      hasDuplicatesInForm,
      duplicateLinkIdsInForm
    });
    return { hasDuplicatesInForm };
  }

  async function checkBackEndIdenticalLinks(providerId, formData) {
    const { hasDuplicatesInBackend, duplicateLinkIdsInBackend } =
      await fetchBackEndIdenticalLinks(providerId, formData);
    setBackEndDuplicates({
      hasDuplicatesInBackend,
      duplicateLinkIdsInBackend
    });
    return { hasDuplicatesInBackend };
  }

  function clearAllVerifyingStateValues() {
    setFrontEndDuplicates({
      hasDuplicatesInForm: false,
      duplicateLinkIdsInForm: []
    });
    setBackEndDuplicates({
      hasDuplicatesInBackend: false,
      duplicateLinkIdsInBackend: []
    });
  }
  return {
    currentlyVerifying,
    setCurrentlyVerifying,
    checkFrontEndIdenticalLinks,
    frontEndDuplicates,
    backEndDuplicates,
    clearAllVerifyingStateValues,
    checkBackEndIdenticalLinks
  };
};
