import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

const TagTypeFilterSelections = ({
  selectionAddActions,
  filterValues,
  handleSearch
}) => {
  const { handleQueryChange } = selectionAddActions;

  const { query } = filterValues;

  return (
    <div className="tagType-filter__selections">
      <TextField
        variant="standard"
        margin="normal"
        label="Search for Query"
        onChange={handleQueryChange}
        onKeyPress={(e) => {
          e.key === "Enter" && handleSearch();
        }}
        value={query}
        fullWidth
      />
    </div>
  );
};

TagTypeFilterSelections.propTypes = {
  selectionAddActions: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired
};

export default TagTypeFilterSelections;
