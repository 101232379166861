import React, { useState, useEffect, useCallback } from "react";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import FilterBox from "./FilterBox/FilterBox";
import Table from "../../components/Table/Table";
import moment from "moment";
import ColumnFilters from "../../components/ColumnsFilter/ColumnsFilter";
import SimpleAccordion from "../../components/Accordion/Accordion";
import { getNDayBeforeDate } from "../../helpers/index";
import { SystemLog } from "../../services/SystemLog";
import VerticalTabMenu from "../../components/VerticalTabMenu/VerticalTabMenu";

const getDateSections = (from, to) => {
  let output = [{ name: `Current all`, value: "" }];
  let currentDate = moment(to);
  let stopDate = moment(from);
  while (stopDate <= currentDate) {
    output.push({
      name: moment(currentDate).format("YYYY-MM-DD"),
      value: moment(currentDate).format("YYYY-MM-DD")
    });
    currentDate = moment(currentDate).subtract(1, "days");
  }

  return output;
};

const SystemLogs = () => {
  const icon = <LibraryBooksIcon fontSize="inherit" color="primary" />;
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  //Table Filters
  const [fromDate, setFromDate] = useState(() => getNDayBeforeDate(5));
  const [toDate, setToDate] = useState(() => getNDayBeforeDate(0));
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC
  const [total, setTotal] = useState(0);
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] =
    useState(0);
  const dateSections = getDateSections(fromDate, toDate);

  //Column Filters [object keys same as value fields]
  const [showColumns, setShowColumns] = useState({
    title: { show: true, title: "Title", value: "title" },
    hostname: { show: false, title: "Hostname", value: "hostname" },
    level: { show: true, title: "Level", value: "level" },
    msg: {
      show: true,
      title: "Message",
      value: "msg"
    },
    formated_time: {
      show: true,
      title: "UTC Time",
      value: "formated_time"
    },
    method: { show: true, title: "Method", value: "method" },
    version: { show: false, title: "Version", value: "version" },
    url: { show: false, title: "URL", value: "url" },
    user: { show: true, title: "User", value: "user" },
    id: { show: true, title: "ID", value: "id" },
    remoteAddress: {
      show: false,
      title: "Remote Address",
      value: "remoteAddress"
    },
    remotePort: { show: false, title: "Remote Port", value: "remotePort" }
  });
  const getRequestParams = useCallback(() => {
    let params = {};
    if (moment(fromDate).isValid()) {
      params["from"] = moment(fromDate).format("YYYY-MM-DD");
    }
    if (moment(toDate).isValid()) {
      params["to"] = moment(toDate).format("YYYY-MM-DD");
    }
    params["page"] = page;
    params["size"] = size;
    if (orderBy === "time" || orderBy === "formated_time") {
      params["order_by"] = "time";
      params["order_direction"] = orderDirection;
    } else if (orderBy === "msg") {
      params["order_by"] = orderBy;
      params["order_direction"] = orderDirection;
    }

    if (currentSelectedSectionIndex !== 0) {
      if (fromDate && toDate) {
        params["date"] = getDateSections(fromDate, toDate)[
          currentSelectedSectionIndex
        ].value;
      }
    }

    return params;
  }, [
    orderBy,
    orderDirection,
    page,
    size,
    toDate,
    fromDate,
    currentSelectedSectionIndex
  ]);

  useEffect(() => {
    if (!size) {
      setSize(50);
    }
    if (!page) {
      setPage(1);
    }
  }, [size, page]);

  const updateColumnShowValue = (name) => {
    let columns = { ...showColumns };
    columns[name].show = !columns[name].show;
    setShowColumns(columns);
  };

  //All column headers to pass down to column filter
  const getAllColumnHeaders = () => {
    let columns = [];
    for (const column in showColumns) {
      columns.push({
        show: showColumns[column].show,
        title: showColumns[column].title,
        value: showColumns[column].value
      });
    }
    return columns;
  };

  //function to get headers based on columns state whose show value are set to true
  const getTableHeaders = () => {
    let headers = [];
    for (const column in showColumns) {
      if (showColumns[column].show) {
        headers.push({
          title: showColumns[column].title,
          value: showColumns[column].value
        });
      }
    }
    return headers;
  };

  //mapping banyan level names to level code
  const getLevelNames = useCallback((code) => {
    switch (code) {
      case 60:
        return "Fatal";
      case 50:
        return "Error";
      case 40:
        return "Warn";
      case 30:
        return "Info";
      case 20:
        return "Debug";
      case 10:
        return "Trace";
      default:
        return code;
    }
  }, []);

  const handleReset = () => {
    setFromDate(getNDayBeforeDate(5));
    setToDate(getNDayBeforeDate(0));
    setCurrentSelectedSectionIndex(0);
  };

  const fetchSystemLogs = useCallback(
    (params = {}) => {
      setLoading(true);
      SystemLog.fetchLogs(params)
        .then((response) => {
          if (response.success) {
            let logs = response.data.logs;
            logs = logs.map((log) => {
              log.title = log?.content?.title || "";
              log.hostname = log?.content.hostname || "";
              log.level = getLevelNames(log.level);
              log.formated_time = moment(log.time).utc().format("LLL");
              log.method = log?.content?.req?.method || "";
              log.version = log?.content?.version || "";
              log.url = log?.content?.req?.url || "";
              log.user = log?.content?.req?.user || "";
              log.id = log?.content?.id;
              log.remoteAddress = log?.content?.req?.remoteAddress || "";
              log.remotePort = log?.content?.req?.remotePort || "";
              return log;
            });
            setLogs(logs);
            setTotal(response.data.total);
            setLoading(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          setLoading(false);
        });
    },
    [getLevelNames]
  );

  useEffect(() => {
    fetchSystemLogs(getRequestParams());
  }, [
    orderBy,
    orderDirection,
    page,
    size,
    toDate,
    fromDate,
    getRequestParams,
    fetchSystemLogs
  ]);

  const changeSize = (value) => {
    setPage(1);
    setSize(value);
  };

  const handleFromDate = (date) => {
    //date to be set is null , set it and return
    if (!date) {
      setFromDate(null);
      return;
    }
    if (!toDate) {
      setFromDate(date);
    } else {
      if (moment(date).isBefore(toDate) || moment(date).isSame(toDate)) {
        setFromDate(date);
      } else {
        setToDate(date);
        setFromDate(date);
      }
    }
  };

  const handleToDate = (date) => {
    if (moment(date).isAfter(fromDate) || moment(date).isSame(fromDate)) {
      setToDate(date);
    } else setToDate(fromDate);
  };

  return (
    <div className="system-logs">
      <div className="system-logs__header">
        <ContentHeader icon={icon} title="System Logs" />
      </div>
      <div className="system-logs__options">
        <div className="system-logs__filter">
          <FilterBox
            fromDate={fromDate}
            setFromDate={handleFromDate}
            toDate={toDate}
            setToDate={handleToDate}
            handleReset={handleReset}
          />
        </div>
        <div className="system-logs__files">
          <h4>Logs From</h4>
          <div className="system-logs__files__list">
            {dateSections && (
              <VerticalTabMenu
                sections={dateSections}
                currentSelectedSectionIndex={currentSelectedSectionIndex}
                setCurrentSelectedSectionIndex={setCurrentSelectedSectionIndex}
              />
            )}
          </div>
        </div>
      </div>
      <div className="system-logs__table">
        <SimpleAccordion header="Select Table Columns">
          <ColumnFilters
            columns={getAllColumnHeaders()}
            handleCheckboxChange={updateColumnShowValue}
          />
        </SimpleAccordion>
        <Table
          headers={getTableHeaders()}
          rowValues={logs}
          isLoading={loading}
          noActions={true}
          noIds={true}
          noCheckboxes={true}
          page={page}
          setPage={setPage}
          size={size}
          setSize={changeSize}
          total={total}
          rowsPerPageOptions={[50, 100, 200]}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          tableType="systemLogs"
        />
      </div>
    </div>
  );
};

export default SystemLogs;
