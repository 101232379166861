import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const DatePicker = ({ value, handleOnChange, label, maxDate, minDate }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        margin="normal"
        label={label}
        format="ll"
        open={isOpen}
        onClick={() => setIsOpen(true)}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        value={value}
        onChange={handleOnChange}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        maxDate={maxDate ? maxDate : new Date("2100-01-01")}
        minDate={minDate ? minDate : new Date("1900-01-01")}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
