import React, { useState } from "react";
import SelectMenu from "../../../components/SelectMenu/SelectMenu";

const TargetBulkActionBox = ({
  handleBulkDeleteAction,
  handleStatusChange,
  handleBulkRestore,
  handleBulkPermanentDelete,
  handlePublishStatusChange,
  archive = false
}) => {
  const [action, setAction] = useState("");

  const handleActionChange = (event) => {
    if (event.target.value === "delete") {
      handleBulkDeleteAction();
    }
    if (event.target.value === "activate") {
      handleStatusChange("activate");
      return;
    }
    if (event.target.value === "de-activate") {
      handleStatusChange("de-activate");
      return;
    }
    if (event.target.value === "restore") {
      handleBulkRestore();
      return;
    }

    if (event.target.value === "published") {
      handlePublishStatusChange("published");
      return;
    }

    if (event.target.value === "draft") {
      handlePublishStatusChange("draft");
      return;
    }

    if (event.target.value === "permanent-delete") {
      handleBulkPermanentDelete();
    }
    setAction("");
  };

  const selectMenu = () => {
    if (archive) {
      return [
        { label: "Unarchive", value: "restore" }
        // { label: "Delete", value: "permanent-delete" }
      ];
    } else {
      return [
        { label: "Activate", value: "activate" },
        { label: "De-activate", value: "de-activate" },
        { label: "Archive", value: "delete" },
        { label: "Publish", value: "published" }
        // { label: "Un-Publish", value: "draft" }
      ];
    }
  };
  return (
    <div className="bulk-action__container">
      <div className="bulk-action-box">
        {/* <h3>Choose</h3> */}
        <SelectMenu
          selected={action}
          data={selectMenu()}
          handleChange={handleActionChange}
          label={"Select Action"}
          showValueInLabel={false}
        />
      </div>
    </div>
  );
};

export default TargetBulkActionBox;
