import React from "react";
import SkippedRowsFilterSelections from "./SkippedRowsFilterSelections";
import SkippedRowsFilterActionButtons from "./SkippedRowsActionButtons";
const SkippedRowsFilter = ({
  filterValuesLogic,
  handleSearch,
  loadingskippedRows
}) => {
  const { filterValues, selectionAddActions, clearAllSelection, dataLists } =
    filterValuesLogic;
  return (
    <div
      className="flow-content skippedRows-filter__container"
      style={{ width: "100%" }}
    >
      <SkippedRowsFilterSelections
        selectionAddActions={selectionAddActions}
        filterValues={filterValues}
        handleSearch={handleSearch}
        dataLists={dataLists}
      />
      <SkippedRowsFilterActionButtons
        handleSearch={handleSearch}
        clearAllSelection={clearAllSelection}
        loadingData={loadingskippedRows.isLoading}
      />
    </div>
  );
};

export default SkippedRowsFilter;
