import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { validate } from "../../../helpers/validation";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/CloseSharp";
import { User } from "../../../services/User";
// import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";

const ProfilePasswordForm = ({ handleCancel, userId }) => {
  // const history = useHistory();
  const [formValues, setFormValues] = useState({
    oldpassword: {
      value: "",
      error: false,
      label: "Old Password",
      type: "password",
      errorMsg: "Please enter your old Password!",
      validOptions: ["isRequired"]
    },
    password: {
      value: "",
      error: false,
      label: "New password",
      type: "password",
      errorMsg: "Please enter new password",
      validOptions: ["isRequired"]
    },
    confirmpassword: {
      value: "",
      error: false,
      label: "Confirm new password",
      type: "password",
      errorMsg: "Please enter same password as above",
      validOptions: ["isRequired"]
    }
  });

  const clearPasswordValues = () => {
    setFormValues({
      oldpassword: {
        ...formValues.oldpassword,
        value: ""
      },
      password: {
        ...formValues.password,
        value: ""
      },
      confirmpassword: {
        ...formValues.confirmpassword,
        value: ""
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //return if form valus is not valid
    if (!validateForm()) {
      return;
    }
    const formData = {
      oldPassword: formValues.oldpassword.value,
      newPassword: formValues.password.value
    };
    User.updatePassword(formData, userId)
      .then((response) => {
        if (response.success) {
          clearPasswordValues();
          toastr.success("Success", "Password updated successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops", JSON.parse(error.message).message);
      });
  };

  const validateForm = () => {
    let isValid = true;
    let tempFormValues = { ...formValues };
    Object.keys(formValues).forEach((key) => {
      formValues[key].validOptions.forEach((option) => {
        if (!validate(option, formValues[key].value)) {
          tempFormValues = {
            ...tempFormValues,
            [key]: {
              ...formValues[key],
              error: true
            }
          };
          isValid = false;
        }
      });
    });
    //checking if new password are same in both fields
    if (formValues["password"].value !== formValues["confirmpassword"].value) {
      isValid = false;
      tempFormValues["password"].error = true;
      tempFormValues["confirmpassword"].error = true;
    }
    setFormValues(tempFormValues);
    return isValid;
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: {
        ...formValues[e.target.name],
        value: e.target.value,
        error: false
      }
    });
  };

  return (
    <div className="profile__password">
      <div className="profile__password__title">
        <h4>Update Password</h4>
        <span>
          Please enter your old password and desired new password below
        </span>
      </div>
      <div className="profile__password__form">
        <h5>Change Password</h5>
        <form onSubmit={handleSubmit}>
          {Object.keys(formValues).map((key) => {
            return (
              <div key={key} className="profile__basic-info__form__field">
                <FormControl fullWidth>
                  {" "}
                  <TextField
                    variant="outlined"
                    label={formValues[key].label}
                    name={key}
                    value={formValues[key].value}
                    error={formValues[key].error}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    autoFocus
                    placeholder={`Enter ${formValues[key].label}`}
                    type={formValues[key].type}
                  />
                  {formValues[key].error && (
                    <FormHelperText>{formValues[key].errorMsg}</FormHelperText>
                  )}
                </FormControl>
              </div>
            );
          })}
          <div className="profile__basic-info__form__buttons">
            <Button
              color="primary"
              variant="contained"
              size="medium"
              startIcon={<CheckIcon />}
              type="submit"
            >
              {" "}
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              startIcon={<CloseIcon />}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfilePasswordForm;
