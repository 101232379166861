import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import LinkInfo from "./layouts/LinkInfo";

const LinkInfoForm = () => {
  return (
    <div className="linkInfoForm">
      <div className="linkInfos">
        <LinkInfo />
        <LinkInfo />
      </div>
      <Button variant="contained" color="primary" startIcon={<AddIcon />}>
        Add more link
      </Button>
    </div>
  );
};

export default LinkInfoForm;
