import React from "react";
import DropDownToolTip from "./DropDownTooltip";
const TooltipOption = ({
  option,
  getLabel,
  showValueInLabel,
  disabledMenuClassName,
  enabledMenuClassName
}) => {
  const isDisabled = option?.disabled || false;
  return (
    <div
      className={`autoselect-value ${
        isDisabled ? disabledMenuClassName : enabledMenuClassName
      }`}
    >
      <DropDownToolTip title={option.label}>
        <div>
          <span>{getLabel(option, showValueInLabel)}</span>
        </div>
      </DropDownToolTip>
    </div>
  );
};

export default TooltipOption;
