import React from "react";
import { useFetchStatsLogic } from "./fetchStats.logic";
import { useDisplayNameLogic } from "../../../../mapping/display-name.logic";
import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import Stats from "./Stats";
const MonthlyStats = () => {
  const { loadingDisplayNames, displayNames } = useDisplayNameLogic();
  const { statsData, loadingStatsData } = useFetchStatsLogic();

  if (loadingStatsData || loadingDisplayNames) {
    return (
      <div className="loading-div">
        <LoadingSpinner />
      </div>
    );
  }
  let { fields } = displayNames || {}; //Mapped Display Name Fields
  const reportStats = statsData?.[0] || {};
  return (
    <div>
      {" "}
      <Stats reportStats={reportStats} fields={fields} />
    </div>
  );
};

export default MonthlyStats;
