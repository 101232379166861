import React from "react";
import Button from "@material-ui/core/Button";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import DatePicker from "../../../components/DatePicker/DatePicker";

const TargetFilterBox = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  handleReset
}) => {
  return (
    <div className="system-logs__filter-box-container">
      <fieldset className="custom-fieldset">
        <legend>Fetch Log Files</legend>
        <div className="system-logs__filter-box">
          <DatePicker
            value={fromDate}
            handleOnChange={setFromDate}
            label="From"
            maxDate={new Date()}
          />
          <DatePicker
            value={toDate}
            handleOnChange={setToDate}
            label="To"
            minDate={fromDate ? fromDate : null}
            maxDate={new Date()}
          />
          <div className="system-logs__filter-box__buttons">
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleReset}
              startIcon={<ClearAllIcon />}
            >
              Reset
            </Button>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default TargetFilterBox;
