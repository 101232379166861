import React from "react";
import InfoSharp from "@material-ui/icons/InfoSharp";

export const ruleArchiveMessage = (
  <div className="rule-archive-message">
    <h2>Are you sure you want to archive?</h2>
    <p>
      <span>
        <InfoSharp
          className="info-icon"
          style={{ color: "blue" }}
          fontSize="small"
        />
      </span>
      <span>
        Rules can only be archived and not deleted to preserve related
        information such as logs!
      </span>
    </p>
  </div>
);
