import React from "react";
import { usePlatformFilters } from "../logic/platformsFilters.logic";
import { useFetchPlatforms } from "../logic/fetchPlatforms.logic";
import { usePlatformTablelogic } from "../logic/platformTable.logic";
// import PlatformTableTabMenu from "./PlatformTableTabMenu";
import Table from "../../../components/Table/Table";
import PlatformTableFilter from "./PlatformTableFilter";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";

const PlatformTable = ({ openEditForm }) => {
  const filterValuesLogic = usePlatformFilters();

  const {
    clearAllSelection,
    filterValues,
    // selectionAddActions,
    filtersClearToggle
  } = filterValuesLogic;

  const {
    loadingPlatforms,
    platforms,
    page,
    setPage,
    size,
    setSize,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    total,
    getPlatforms
  } = useFetchPlatforms({
    filterValues,
    filtersClearToggle
  });

  // const { setArchivedData } = selectionAddActions;

  const {
    headers,
    actions,
    archiveModalOpen,
    deleteModalOpen,
    restoreModalOpen,
    handleModalClose,
    archivePlatform,
    deletePlatform,
    restorePlatform
  } = usePlatformTablelogic({ filterValues, getPlatforms, openEditForm });

  return (
    <div className="flow-content">
      {/*}
      {/* <PlatformTableTabMenu
        archivedData={filterValues.archivedData}
        setArchivedData={setArchivedData}
      /> */}

      <PlatformTableFilter
        handleSearch={getPlatforms}
        clearAllSelection={clearAllSelection}
        filterValuesLogic={filterValuesLogic}
        loadingPlatforms={loadingPlatforms}
      />
      <div>
        {platforms && (
          <Table
            headers={headers}
            rowValues={platforms}
            actions={actions}
            isLoading={loadingPlatforms.isLoading}
            noIds={true}
            noCheckboxes={true}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            total={total}
            rowsPerPageOptions={[10, 20, 30, 50, 100, 200]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            tableType="platforms"
          />
        )}
      </div>
      {archiveModalOpen && (
        <ConfirmBox
          title="Archive Platform"
          message="Are you sure you want to archive this platform?"
          confirmAction={archivePlatform}
          denyAction={handleModalClose}
        />
      )}
      {deleteModalOpen && (
        <ConfirmBox
          title="Delete Platform"
          message="Are you sure you want to delete this platform?"
          confirmAction={deletePlatform}
          denyAction={handleModalClose}
        />
      )}
      {restoreModalOpen && (
        <ConfirmBox
          title="Restore Platform"
          message="Are you sure you want to restore this platform?"
          confirmAction={restorePlatform}
          denyAction={handleModalClose}
        />
      )}
    </div>
  );
};

export default PlatformTable;
