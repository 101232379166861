import { useState, useEffect } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { textFieldValid } from "../../../helpers/validation";
import { PublisherAccount } from "../../../services/publisherAccounts";
import { toastr } from "react-redux-toastr";
export const useFetchPublisherAccounts = ({
  filterValues,
  filtersClearToggle
}) => {
  const [publisherAccounts, setpublisherAccounts] = useState([]);
  const [loadingpublisherAccounts, setLoadingpublisherAccounts] = useState({
    isLoading: false,
    error: false
  });

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [total, setTotal] = useState(0);

  function getParams() {
    let params = {};
    const {
      query,
      selectedProviders,
      selectedPublishers,
      selectedTagIds,
      selectedRuleIds
    } = filterValues;
    if (textFieldValid(query)) {
      params["q"] = query.trim();
    }
    if (orderBy) {
      params["order_by"] = orderBy;
    }

    if (selectedProviders?.length > 0) {
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["provider_id"] = selection;
    }

    if (selectedPublishers?.length > 0) {
      let selection = [];
      for (let i in selectedPublishers) {
        selection.push(selectedPublishers[i]);
      }
      params["publisher_id"] = selection;
    }
    if (selectedTagIds?.length > 0) {
      let selection = [];
      for (let i in selectedTagIds) {
        selection.push(selectedTagIds[i]);
      }
      params["tid"] = selection;
    }
    if (selectedRuleIds?.length > 0) {
      let selection = [];
      for (let i in selectedRuleIds) {
        selection.push(selectedRuleIds[i]);
      }
      params["rule_id"] = selection;
    }

    params["order_direction"] = orderDirection;

    params["page"] = page;
    params["size"] = size;
    return params;
  }

  const isMounted = useIsMounted();

  function formatpublisherAccounts(publisherAccounts) {
    return publisherAccounts.map((publisherAccount) => {
      return {
        ...publisherAccount,
        publisher_id: `${publisherAccount?.publisher?.id || ""} - ${
          publisherAccount?.publisher?.name || ""
        }`,
        provider_id: `${publisherAccount?.provider?.id || ""} - ${
          publisherAccount?.provider?.name || ""
        }`
      };
    });
  }

  async function updateStatus(id, currentStatuses) {
    const poststatus = await PublisherAccount.updateAccounts(id, {
      status: currentStatuses
    });
    if (poststatus.success) {
      getpublisherAccounts();
      toastr.success("Success", "Status updated");
    } else {
      toastr.error("Oops", "Failed to update");
    }
  }

  async function getpublisherAccounts() {
    try {
      setLoadingpublisherAccounts({ isLoading: true, error: false });
      const response = await PublisherAccount.fetchPublisherAccounts(
        getParams()
      );
      if (response.success && isMounted()) {
        const fetchedpublisherAccounts = response.data.data || [];
        setpublisherAccounts(formatpublisherAccounts(fetchedpublisherAccounts));
        setTotal(response.data.total);
        setLoadingpublisherAccounts({ isLoading: false, error: false });
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setpublisherAccounts([]);
        setLoadingpublisherAccounts({ isLoading: false, error: true });
      }
      console.trace(error.message || error);
    }
  }
  useEffect(() => {
    getpublisherAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, orderBy, orderDirection, filtersClearToggle]);

  return {
    publisherAccounts,
    loadingpublisherAccounts,
    page,
    size,
    orderBy,
    orderDirection,
    total,
    getpublisherAccounts,
    setPage,
    setSize,
    setOrderBy,
    setOrderDirection,
    updateStatus
  };
};
