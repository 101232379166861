import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import ReportingLogsTable from "../reportingLogs/reportingLogsTable";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import GetAppIcon from "@material-ui/icons/GetApp";
function ReportingLogs() {
  const icon = <GetAppIcon fontSize="inherit" color="primary" />;
  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <div className="reporting-title">
          <div className="publishers-container__header">
            <ContentHeader icon={icon} title="Reporting Logs" />
          </div>
        </div>
        <div className="reporting-content">
          <ReportingLogsTable />
        </div>
      </Container>
    </React.Fragment>
  );
}

export default ReportingLogs;
