import React, { useState, useCallback, useEffect } from "react";
import SimpleModal from "../SimpleModal/SimpleModal";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SimpleDateRangePicker from "../SimpleDateRangePicker/SimpleDateRangePicker";
import AutoCompleteSelect from "../AutoCompleteSelect/AutoCompleteSelect";
import FormHelperText from "@material-ui/core/FormHelperText";

import { Provider } from "../../services/Provider";
import { Publisher } from "../../services/Publisher";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../helpers/constant/misc";
import { useMultipleSelect } from "../../hooks/useMultipleSelect";

import moment from "moment";
const ConfirmBoxWithForm = ({
  message,
  confirmAction,
  denyAction,
  hideCloseButton = false,
  type = null,
  selected = null
}) => {
  const [confirm, setConfirm] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    setData: setProviders,
    selectedData: selectedProviders,
    data: providers,
    handleSelectedDataChange: handleProviderChange
  } = useMultipleSelect();

  const {
    setData: setPublishers,
    selectedData: selectedPublishers,
    data: publishers,
    handleSelectedDataChange: handleSearchPublisherChange
  } = useMultipleSelect();

  const handleToDate = (date) => {
    if (!date) {
      setToDate(null);
      return;
    }
    if (!fromDate) {
      setToDate(date);
    } else {
      if (moment(date).isAfter(fromDate) || moment(date).isSame(fromDate)) {
        setToDate(date);
      } else setToDate(fromDate);
    }
  };

  const handleFromDate = (date) => {
    if (!date) {
      setFromDate(null);
      return;
    }
    if (!toDate) {
      setFromDate(date);
    } else {
      if (moment(date).isBefore(toDate) || moment(date).isSame(toDate)) {
        setFromDate(date);
      } else {
        setToDate(date);
        setFromDate(date);
      }
    }
  };

  const handleDateChange = (fromDate, toDate) => {
    setError(false);
    setErrorMessage("");
    handleFromDate(fromDate);
    handleToDate(toDate);
  };

  const validateForm = () => {
    if (confirm) {
      if (!fromDate || !toDate) {
        setError(true);
        setErrorMessage("Please select date range");
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const submitDate = () => {
    if (validateForm()) {
      confirmAction({
        fromDate,
        toDate,
        selectedProviders,
        selectedPublishers
      });
    }
    setConfirm(true);
  };

  const fetchProviders = useCallback(() => {
    Provider.fetchProviders({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.providers;
          const providers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            providers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setProviders(providers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPublishers = useCallback(() => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            publishers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchProviders();
    fetchPublishers();
  }, [fetchProviders, fetchPublishers]);

  return (
    <SimpleModal handleClose={denyAction}>
      <div className="delete-confirm-box">
        <Typography component="h3">{message}</Typography>
        {confirm && (
          <div>
            {selected.length <= 1 && (
              <div className="confirm-date-form confirm-with-form">
                {type === "publisher" && (
                  <>
                    <Typography component="h3">Select Advertiser</Typography>
                    <AutoCompleteSelect
                      selected={selectedProviders}
                      data={providers}
                      handleChange={handleProviderChange}
                      label={"Advertisers"}
                      showValueInLabel={true}
                      enableMultiple={true}
                      withDotsForStatus={true}
                    />
                  </>
                )}
                {type === "provider" && (
                  <>
                    <Typography component="h3">Select Publisher</Typography>
                    <AutoCompleteSelect
                      selected={selectedPublishers}
                      data={publishers}
                      handleChange={handleSearchPublisherChange}
                      label={"Publishers"}
                      showValueInLabel={true}
                      enableMultiple={true}
                      withDotsForStatus={true}
                    />
                  </>
                )}
              </div>
            )}
            <div className="confirm-date-form">
              <Typography component="h3">Select Date</Typography>
              <div>
                <SimpleDateRangePicker
                  startDate={fromDate ? fromDate : null}
                  endDate={toDate ? toDate : null}
                  handleDateChange={handleDateChange}
                  ate
                />
                {error && (
                  <FormHelperText color="secondary" style={{ color: "red" }}>
                    {errorMessage}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="delete-confirm-box__buttons">
          <Button variant="contained" color="secondary" onClick={submitDate}>
            Yes
          </Button>
          {!hideCloseButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => denyAction()}
            >
              No
            </Button>
          )}
        </div>
      </div>
    </SimpleModal>
  );
};

export default ConfirmBoxWithForm;
