import React from "react";
import PropTypes from "prop-types";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

const PublisherAccountFilterActionButtons = ({
  clearAllSelection,
  handleSearch,
  loadingData
}) => {
  return (
    <div
      style={{ display: "flex", gap: "10px" }}
      className="platform-filter__action-buttons"
    >
      <Button
        variant="contained"
        size="medium"
        color={loadingData ? "default" : "primary"}
        disabled={loadingData}
        onClick={handleSearch}
        startIcon={<SearchIcon />}
      >
        Search
      </Button>
      <Button
        variant="contained"
        size="medium"
        color={loadingData ? "default" : "secondary"}
        disabled={loadingData}
        onClick={clearAllSelection}
        startIcon={<ClearAllIcon />}
      >
        Clear All
      </Button>
    </div>
  );
};

PublisherAccountFilterActionButtons.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  clearAllSelection: PropTypes.func.isRequired,
  loadingData: PropTypes.bool.isRequired
};

export default PublisherAccountFilterActionButtons;
