import { SettingActionTypes } from "./setting.action";

/*
changing array of objects [settings] to
an object in settingsObject for ease of access
of default domain and fallback url.
*/
const INITIAL_STATE = {
  settings: [],
  settingsObject: {}
};

const settingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SettingActionTypes.UPDATE_DEFAULT_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };
    case SettingActionTypes.UPDATE_SETTINGS_OBJECT:
      return {
        ...state,
        settingsObject: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default settingReducer;
