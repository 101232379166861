import { useState, useEffect } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { TagType } from "../../../services/TagType";
import { textFieldValid } from "../../../helpers/validation";

export const useFetchTagTypes = ({ filterValues, filtersClearToggle }) => {
  const [tagTypes, setTagTypes] = useState([]);
  const [loadingTagTypes, setLoadingTagTypes] = useState({
    isLoading: false,
    error: false
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [total, setTotal] = useState(0);

  function getParams() {
    let params = {};
    const { query, archivedData } = filterValues;
    if (textFieldValid(query)) {
      params["q"] = query.trim();
    }

    if (archivedData) {
      params["archived"] = archivedData;
    }
    if (orderBy) {
      params["order_by"] = orderBy;
    }
    params["order_direction"] = orderDirection;

    params["page"] = page;
    params["size"] = size;

    return params;
  }

  const isMounted = useIsMounted();

  async function getTagTypes() {
    try {
      setLoadingTagTypes({ isLoading: true, error: false });
      const tagTypes = await TagType.fetchTagTypes(getParams());
      if (tagTypes.success && isMounted()) {
        setTagTypes(tagTypes.data.tag_types);
        setTotal(tagTypes.data.total);
        setLoadingTagTypes({ isLoading: false, error: false });
      } else {
        throw new Error(tagTypes.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setTagTypes([]);
        setLoadingTagTypes({ isLoading: false, error: true });
      }
      console.trace(error.message || error);
    }
  }

  useEffect(() => {
    getTagTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    size,
    orderBy,
    orderDirection,
    filtersClearToggle,
    filterValues.archivedData
  ]);

  return {
    loadingTagTypes,
    tagTypes,
    page,
    setPage,
    size,
    setSize,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    total,
    getTagTypes
  };
};
