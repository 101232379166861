import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import { Provider } from "../../../../services/Provider";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import AutoCompleteSelect from "../../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../../helpers/constant/misc";
import SimpleDateRangePicker from "../../../../components/SimpleDateRangePicker/SimpleDateRangePicker";
import { STATUS } from "../constant/status";
import moment from "moment";

const FilterBox = ({
  provider,
  setProvider,
  handleFilter,
  status,
  setStatus,
  fromDate,
  setFromDate,
  toDate,
  setToDate
}) => {
  const [providers, setProviders] = useState(null);

  const handleSearchButton = () => {
    handleFilter();
  };

  const fetchProviders = () => {
    Provider.fetchProviders({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.providers;
          const providers = [];
          for (let p of data) {
            providers.push({ label: p.name, value: p.id });
          }
          setProviders(providers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  };
  useEffect(() => {
    fetchProviders();
  }, []);

  const handleProviderChange = (value) => {
    setProvider(value);
  };

  const HandleStatusChange = (value) => {
    setStatus(value);
  };
  const handleClearAll = () => {
    setProvider("");
    setStatus("");
    setFromDate(null);
    setToDate(null);
  };

  const handleToDate = (date) => {
    if (!date) {
      setToDate(null);
      return;
    }
    if (!fromDate) {
      setToDate(date);
    } else {
      if (moment(date).isAfter(fromDate) || moment(date).isSame(fromDate)) {
        setToDate(date);
      } else setToDate(fromDate);
    }
  };

  const handleFromDate = (date) => {
    if (!date) {
      setFromDate(null);
      return;
    }
    if (!toDate) {
      setFromDate(date);
    } else {
      if (moment(date).isBefore(toDate) || moment(date).isSame(toDate)) {
        setFromDate(date);
      } else {
        setToDate(date);
        setFromDate(date);
      }
    }
  };

  const handleDateChange = (fromDate, toDate) => {
    handleFromDate(fromDate);
    handleToDate(toDate);
  };

  return (
    <div className="filter-box__container">
      <div className="filter-box">
        <div className="filter-box__controls">
          <div className="filter-box__options">
            <div className="filter-box__options-select">
              <AutoCompleteSelect
                selected={provider ? provider : ""}
                data={providers || []}
                handleChange={(value) => handleProviderChange(value)}
                label={"Advertiser"}
                showValueInLabel={true}
                enableMultiple={false}
                withDotsForStatus={true}
                cannotSelectDisabled={false}
              />
              <AutoCompleteSelect
                selected={status ? status : ""}
                data={STATUS || []}
                handleChange={(value) => HandleStatusChange(value)}
                label={"Status"}
                showValueInLabel={false}
                enableMultiple={false}
                withDotsForStatus={false}
                cannotSelectDisabled={false}
              />
              <SimpleDateRangePicker
                startDate={fromDate ? fromDate : null}
                endDate={toDate ? toDate : null}
                handleDateChange={handleDateChange}
              />
            </div>
          </div>
        </div>
        <div className="filter-box__button">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSearchButton}
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleClearAll}
            startIcon={<ClearAllIcon />}
          >
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterBox;
