import React, { useState } from "react";
import SelectMenu from "../SelectMenu/SelectMenu";
/**
 * Component for use in Publisher, Advertiser(Provider) listing page.
 * Lists different bulk action through a select box.
 * PROPS: archive(state to track if the list is normal or archived data),
 * different bulk action handlers
 * accordion
 * returns: Bulk Action Box
 */
const BulkActionBox = ({
  handleBulkDeleteAction,
  handleStatusChange,
  handleBulkRestore,
  handleBulkPermanentDelete,
  handleDeleteRevenue,
  type,
  archive = false
}) => {
  const [action, setAction] = useState("");

  const handleActionChange = (event) => {
    if (event.target.value === "delete") {
      handleBulkDeleteAction();
      return;
    }
    if (event.target.value === "activate") {
      handleStatusChange("activate");
      return;
    }
    if (event.target.value === "de-activate") {
      handleStatusChange("de-activate");
      return;
    }

    if (event.target.value === "restore") {
      handleBulkRestore();
      return;
    }

    if (event.target.value === "permanent-delete") {
      handleBulkPermanentDelete();
    }
    if (event.target.value === "delete-revenue") {
      handleDeleteRevenue();
    }
    setAction("");
  };

  const selectMenu = () => {
    let menuOptions = [];

    if (archive) {
      menuOptions.push({ label: "Unarchive", value: "restore" });
      // if (type === "publisher")
      //   menuOptions.push({ label: "Delete", value: "permanent-delete" });
    } else {
      menuOptions = [
        { label: "Activate", value: "activate" },
        { label: "De-activate", value: "de-activate" },
        { label: "Archive", value: "delete" }
      ];
    }
    if (type === "publisher" || type === "provider") {
      menuOptions.push({
        label: "Delete revenue data",
        value: "delete-revenue"
      });
    }

    return menuOptions;
  };

  return (
    <div className="bulk-action__container">
      <div className="bulk-action-box">
        {/* <h3>Choose</h3> */}
        <SelectMenu
          selected={action}
          data={selectMenu()}
          handleChange={handleActionChange}
          label={"Select Action"}
          showValueInLabel={false}
        />
      </div>
    </div>
  );
};

export default BulkActionBox;
