import React from "react";
import ProfilePicture from "../../../components/ProfilePicture/ProfilePicture";

const ProfileSummary = ({ profile }) => {
  const { first_name, last_name } = profile;

  return (
    <div className="profile__summary">
      <div className="profile__summary__photo">
        <ProfilePicture imageLink={profile.profile_link} />
      </div>
      <h4 className="profile__summary__title">
        Welcome
        {` ${first_name}  ${last_name} `}
      </h4>
      <div className="profile__summary__info">
        {/* ADD Some Profile Info Here */}
      </div>
    </div>
  );
};

export default ProfileSummary;
