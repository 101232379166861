import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { User } from "../../../services/ReportingUser";
import { useFormTouched } from "../../../hooks/useFormTouched";

const initialFormValues = {
  // username: "",
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  cmpassword: "",
  publisher_id: "",
  role_id: "",
  // provider_id: "",
  // account_id: "",
  blocked: false
};

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction: () => {}
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    // if ("username" in fieldValues){
    //     temp.username = fieldValues.username ? "" : "Username is required.";
    // }
    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "Email is required.";
      if (fieldValues.email) {
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
      }
    }
    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : "Password field is required.";
    }
    if ("cmpassword" in fieldValues) {
      temp.cmpassword = fieldValues.cmpassword
        ? ""
        : "Confirm password field is required.";
      if (fieldValues.cmpassword) {
        temp.cmpassword =
          values.password === fieldValues.cmpassword
            ? ""
            : "Password should be match";
      }
    }
    if ("publisher_id" in fieldValues) {
      temp.publisher_id = fieldValues.publisher_id
        ? ""
        : "Publisher field is required.";
    }
    // if ("provider_id" in fieldValues) {
    //   temp.provider_id = fieldValues.provider_id
    //     ? ""
    //     : "Provider field is required.";
    // }
    // if ("account_id" in fieldValues) {
    //   temp.account_id = fieldValues.account_id
    //     ? ""
    //     : "Account field is required.";
    // }
    if ("role_id" in fieldValues) {
      temp.role_id = fieldValues.role_id ? "" : "Role field is required.";
    }
    setErrors({
      ...temp
    });
  };

  const PostContactForm = async (values, successCallback, errorCallback) => {
    delete values["cmpassword"];
    const response = await User.addUser(values);
    if (response.success) {
      successCallback();
      toastr.success("Success", "User Added Successfully!");
      history.push("/usermanagement");
    } else {
      toastr.error("Failed", response?.error?.message);
      errorCallback();
    }
  };

  const handleInputValue = (e) => {
    handleFormTouched();
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    validate({ [name]: value });
  };

  const handleselectbox = (value, name) => {
    handleFormTouched();
    setValues({
      ...values,
      [name]: value
    });
    validate({ [name]: value });
  };

  const handleSuccess = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: true
    });
  };

  const handleError = () => {
    // setValues({
    //   ...initialFormValues,
    //   formSubmitted: true,
    //   success: false
    // });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      // fieldValues.username &&
      fieldValues.email &&
      fieldValues.cmpassword &&
      fieldValues.publisher_id &&
      // fieldValues.provider_id &&
      fieldValues.role_id &&
      // fieldValues.account_id &&
      fieldValues.password &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  const handleFormSubmit = async (e, values) => {
    e.preventDefault();
    validate({ ...values });
    const isValid =
      Object.values(errors).every((x) => x === "") && formIsValid();
    if (isValid) {
      clearFormTouched();
      await PostContactForm({ ...values }, handleSuccess, handleError);
    }
  };

  return {
    values,
    errors,
    handleInputValue,
    handleselectbox,
    handleFormSubmit,
    formIsValid,
    formTouched
  };
};
