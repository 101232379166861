import React from "react";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { usePublisherAccountsFilters } from "./logic/publisherAccountFilters.logic";
import { useFetchPublisherAccounts } from "./logic/fetchPublisherAccount.logic";
import PublisherAccountFilter from "./layouts/PublisherAccountFilter";
import PublisherAccountTable from "./layouts/PublisherAccountsTable";
import SimpleAccordion from "../../components/Accordion/Accordion";
const PublisherAccountList = () => {
  const icon = <AccountBalanceWalletIcon fontSize="inherit" color="primary" />;
  const filterValuesLogic = usePublisherAccountsFilters();
  const { filterValues, filtersClearToggle } = filterValuesLogic;
  const fetchpublisherAccountsLogic = useFetchPublisherAccounts({
    filterValues,
    filtersClearToggle
  });
  const { getpublisherAccounts, loadingpublisherAccounts } =
    fetchpublisherAccountsLogic;

  return (
    <div className="publisher-list__container">
      <div className="publisher-list__header">
        <ContentHeader icon={icon} title="Publisher Ad Accounts List" />
      </div>
      <div className="flow-content">
        <SimpleAccordion
          header="Filters"
          key="publisherAccounts-filters"
          defaultExpanded={true}
        >
          <PublisherAccountFilter
            filterValuesLogic={filterValuesLogic}
            handleSearch={getpublisherAccounts}
            loadingpublisherAccounts={loadingpublisherAccounts}
          />
        </SimpleAccordion>
        <PublisherAccountTable
          fetchpublisherAccountsLogic={fetchpublisherAccountsLogic}
        />
      </div>
    </div>
  );
};

export default PublisherAccountList;
