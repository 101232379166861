import React from "react";
import PropTypes from "prop-types";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Button from "@material-ui/core/Button";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

const PublisherAccountRemoveButtons = ({
  cancelAction,
  submitAction,
  loading
}) => {
  return (
    <div
      style={{ display: "flex", gap: "10px" }}
      className="platform-filter__action-buttons"
    >
      <Button
        variant="contained"
        size="medium"
        color={loading ? "default" : "primary"}
        disabled={loading}
        onClick={submitAction}
        startIcon={<DeleteSweepIcon />}
      >
        Remove Data
      </Button>
      <Button
        variant="contained"
        size="medium"
        color={loading ? "default" : "secondary"}
        disabled={loading}
        onClick={cancelAction}
        startIcon={<ClearAllIcon />}
      >
        Cancel
      </Button>
    </div>
  );
};

PublisherAccountRemoveButtons.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default PublisherAccountRemoveButtons;
