import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { useHistory } from "react-router-dom";
//component to show user
const WarningBox = () => {
  const history = useHistory();
  return (
    <div
      className="warning-box box-shadow"
      onClick={() => {
        history.push("/settings");
      }}
    >
      <WarningIcon className="blink" style={{ fontSize: 50 }} />
      <div className="warning">
        <div className="warning__header">
          <h4>Uh oh, Default Variables not set.</h4>
        </div>
        <div className="warning__sub-header">
          Default Domain or System Fallback not set. Click here to set both of
          them.
        </div>
      </div>
    </div>
  );
};

export default WarningBox;
