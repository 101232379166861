import { useState, useEffect, useCallback } from "react";
import { Provider } from "../services/Provider";
import { DEFAULT_FETCH_PARAM_SIZE } from "../helpers/constant/misc";
/**
 * Logic to fetch providers and selected a particular provider*
 * @returns List of Providers, Selected Provider and Set Provider Function
 */
export const useSelectProviders = () => {
  const [providers, setProviders] = useState([]);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [provider, setProvider] = useState("");

  const fetchProviders = useCallback(
    (
      params = {
        order_by: "id",
        order_direction: "ASC",
        size: DEFAULT_FETCH_PARAM_SIZE
      }
    ) => {
      setLoadingProviders(true);
      Provider.fetchProviders(params)
        .then((response) => {
          if (response.success) {
            const data = response.data.providers;
            const providers = [];
            for (let p of data) {
              let isInactive = p.status === "inactive";
              providers.push({
                label: p.name,
                value: p.id,
                status: p.status,
                disabled: isInactive
              });
            }
            setProviders(providers);
            setLoadingProviders(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          setLoadingProviders(false);
        });
    },
    []
  );
  // Loading Providers(Advertisers) on intial load
  useEffect(() => {
    fetchProviders();
  }, [fetchProviders]);

  return {
    providers,
    provider,
    setProvider,
    loadingProviders
  };
};
