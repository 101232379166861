import { useState, useEffect } from "react";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { TagType } from "../../../services/TagType";

export const useFetchTagTypes = () => {
  const [tagTypes, setTagTypes] = useState([]);

  const [loadingTagTypes, setLoadingTagTypes] = useState({
    isLoading: false,
    error: false
  });

  const isMounted = useIsMounted();

  function getParams() {
    let params = {};

    params["order_by"] = "id";
    params["order_direction"] = "ASC";
    params["size"] = DEFAULT_FETCH_PARAM_SIZE;

    return params;
  }

  async function getTagTypes() {
    try {
      setLoadingTagTypes({ isLoading: true, error: false });
      const tagTypes = await TagType.fetchTagTypes(getParams());
      if (tagTypes.success && isMounted()) {
        let receivedTagTypes = [];
        for (let p of tagTypes.data.tag_types) {
          receivedTagTypes.push({
            label: p.name,
            value: p.id,
            status: p.status
          });
        }
        setTagTypes(receivedTagTypes);
        setLoadingTagTypes({ isLoading: false, error: false });
      } else {
        throw new Error(tagTypes.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setTagTypes([]);
        setLoadingTagTypes({ isLoading: false, error: true });
      }
      console.trace(error.message || error);
    }
  }

  useEffect(() => {
    getTagTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loadingTagTypes,
    tagTypes
  };
};
