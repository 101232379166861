import { useState, useEffect } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { Platform } from "../../../services/Platform";
import { textFieldValid } from "../../../helpers/validation";

export const useFetchPlatforms = ({ filterValues, filtersClearToggle }) => {
  const [platforms, setPlatforms] = useState([]);
  const [loadingPlatforms, setLoadingPlatforms] = useState({
    isLoading: false,
    error: false
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [total, setTotal] = useState(0);

  function getParams() {
    let params = {};
    const { query, archivedData } = filterValues;
    if (textFieldValid(query)) {
      params["q"] = query.trim();
    }

    if (archivedData) {
      params["archived"] = archivedData;
    }
    if (orderBy) {
      params["order_by"] = orderBy;
    }
    params["order_direction"] = orderDirection;

    params["page"] = page;
    params["size"] = size;

    return params;
  }

  const isMounted = useIsMounted();

  async function getPlatforms() {
    try {
      setLoadingPlatforms({ isLoading: true, error: false });
      const platforms = await Platform.fetchPlatforms(getParams());
      if (platforms.success && isMounted()) {
        setPlatforms(platforms.data.platforms);
        setTotal(platforms.data.total);
        setLoadingPlatforms({ isLoading: false, error: false });
      } else {
        throw new Error(platforms.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setPlatforms([]);
        setLoadingPlatforms({ isLoading: false, error: true });
      }
      console.trace(error.message || error);
    }
  }

  useEffect(() => {
    getPlatforms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    size,
    orderBy,
    orderDirection,
    filtersClearToggle,
    filterValues.archivedData
  ]);

  return {
    loadingPlatforms,
    platforms,
    page,
    setPage,
    size,
    setSize,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    total,
    getPlatforms
  };
};
