import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { useSubmitPlatformLogic } from "./submitPlatform.logic";
import { useFormTouched } from "../../../hooks/useFormTouched";
import { changeEmptyStringToNull } from "../../../helpers";
import { Platform } from "../../../services/Platform";
const initialFormValues = {
  name: "",
  details: "",
  status: true
};

const initialErrorValues = {
  name: false,
  details: false
};

export const usePlatformFormLogic = (platformID, openTable) => {
  const [formValues, setFormValues] = useState(() => initialFormValues);
  const [errorValues, setErrorValues] = useState(() => initialErrorValues);

  const { isSubmitting, savePlatform, updatePlatform } =
    useSubmitPlatformLogic();

  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction: () => {}
  });

  const [editing, setIsEditing] = useState({
    id: "",
    isEditing: false
  });

  const [loadingPlatformData, setLoadingPlatformData] = useState({
    isLoading: false,
    error: false
  });

  const isMounted = useIsMounted();

  async function getPlaform(platformId) {
    try {
      setLoadingPlatformData({ isLoading: true, error: false });
      const platform = await Platform.fetchPlatform(platformId);
      if (platform.success && isMounted()) {
        setLoadingPlatformData({ isLoading: false, error: false });
        setFormValues({
          name: platform.data.platform.name || "",
          details: platform.data.platform.details || "",
          status: platform.data.platform.status || true
        });
        setIsEditing({
          id: platform.data.platform.id,
          isEditing: true
        });
      } else {
        throw new Error(platform.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setLoadingPlatformData({ isLoading: false, error: true });
        console.trace(error.message);
        toastr.warning("Oops!", error.message);
      }
    }
  }

  const handleFormValueChange = (e) => {
    handleFormTouched();
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    setErrorValues({
      ...errorValues,
      [name]: false
    });
  };

  const handleStatusChange = () => {
    handleFormTouched();
    setFormValues({
      ...formValues,
      status: !formValues.status
    });
  };

  const handleAddPlatform = async (dataToSend) => {
    const savedPlatform = await savePlatform(dataToSend);
    if (savedPlatform.success) {
      toastr.success("Success!", savedPlatform.msg);
      setIsEditing({
        id: savedPlatform.data.id,
        isEditing: true
      });
      clearFormTouched();
      openTable();
    } else {
      toastr.error("Oops!", savedPlatform.msg);
    }
  };

  const handleUpdatePlatform = async (dataToSend) => {
    const updatedPlatform = await updatePlatform(editing.id, dataToSend);
    if (updatedPlatform.success) {
      toastr.success("Success!", updatedPlatform.msg);
      clearFormTouched();
      openTable();
    } else {
      toastr.error("Oops!", updatedPlatform.msg);
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!changeEmptyStringToNull(formValues.name)) {
      setErrorValues({
        ...errorValues,
        name: true
      });
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    let dataToSend = {
      name: formValues.name.trim(),
      details: changeEmptyStringToNull(formValues.details),
      status: formValues.status
    };
    if (editing.isEditing) {
      handleUpdatePlatform(dataToSend);
    } else {
      handleAddPlatform(dataToSend);
    }
  };

  const handleCancel = () => {
    clearFormTouched();
    openTable();
  };

  useState(() => {
    if (platformID) {
      getPlaform(platformID);
    }
  }, [platformID]);

  return {
    formValues,
    errorValues,
    loadingPlatformData,
    handleFormValueChange,
    handleStatusChange,
    handleSubmit,
    handleCancel,
    isSubmitting,
    editing,
    formTouched
  };
};
