const ApiDetailsActionTypes = {
  FETCH_START: "FETCH_START",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  FETCH_FAILURE: "FETCH_FAILURE",
  ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
  FETCH_LIST_START: "FETCH_LIST_START",
  FETCH_LIST_FAILURE: "FETCH_LIST_FAILURE",
  FETCH_LIST_SUCCESS: "FETCH_LIST_SUCCESS",
  UPDATE_LIST: "UPDATE_LIST"
};

export default ApiDetailsActionTypes;
