import { useState, useEffect } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { SearchEngine } from "../../../services/SearchEngine";
import { textFieldValid } from "../../../helpers/validation";

export const useFetchSearchEngines = ({ filterValues, filtersClearToggle }) => {
  const [searchEngines, setSearchEngines] = useState([]);
  const [loadingSearchEngines, setLoadingSearchEngines] = useState({
    isLoading: false,
    error: false
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [total, setTotal] = useState(0);

  function getParams() {
    let params = {};
    const { query, archivedData } = filterValues;
    if (textFieldValid(query)) {
      params["q"] = query.trim();
    }

    if (archivedData) {
      params["archived"] = archivedData;
    }
    if (orderBy) {
      params["order_by"] = orderBy;
    }
    params["order_direction"] = orderDirection;

    params["page"] = page;
    params["size"] = size;

    return params;
  }

  const isMounted = useIsMounted();

  async function getSearchEngines() {
    try {
      setLoadingSearchEngines({ isLoading: true, error: false });
      const searchEngines = await SearchEngine.fetchSearchEngines(getParams());
      if (searchEngines.success && isMounted()) {
        setSearchEngines(searchEngines.data.search_engines);
        setTotal(searchEngines.data.total);
        setLoadingSearchEngines({ isLoading: false, error: false });
      } else {
        throw new Error(searchEngines.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setSearchEngines([]);
        setLoadingSearchEngines({ isLoading: false, error: true });
      }
      console.trace(error.message || error);
    }
  }

  useEffect(() => {
    getSearchEngines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    size,
    orderBy,
    orderDirection,
    filtersClearToggle,
    filterValues.archivedData
  ]);

  return {
    loadingSearchEngines,
    searchEngines,
    page,
    setPage,
    size,
    setSize,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    total,
    getSearchEngines
  };
};
