import { useEffect, useRef } from "react";

function handlePageExit(e) {
  e.preventDefault();
  e.returnValue = "Form data not saved! Are you sure?";
}

export const useFormTouched = ({ cleanupFunction = () => {} }) => {
  const formTouched = useRef(false);
  const noOfEventListener = useRef(0);

  const handleFormTouched = () => {
    if (noOfEventListener.current === 0) {
      noOfEventListener.current = 1;
      window.addEventListener("beforeunload", handlePageExit);
    }
    formTouched.current = true;
  };

  const clearFormTouched = () => {
    formTouched.current = false;
    noOfEventListener.current = 0;
    window.removeEventListener("beforeunload", handlePageExit);
  };

  useEffect(() => {
    return () => {
      noOfEventListener.current = 0;
      window.removeEventListener("beforeunload", handlePageExit);
      cleanupFunction();
    };
  }, [cleanupFunction]);

  return {
    formTouched,
    handleFormTouched,
    clearFormTouched
  };
};
