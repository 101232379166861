import React from "react";

const NoUserIdMsg = () => (
  <div className="noData_container">
    <h3>Hmmm...</h3>
    <p>
      We couldn't read the user id from the url. Please select a user from the
      list.
    </p>
  </div>
);

export default NoUserIdMsg;
