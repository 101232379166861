import React from 'react';

const NoData = () => {
    return(
        <div className="noData_container">
             <h3>Hmmm...</h3>
             <p>
                 We couldn't find any data to show.<br/>
                It seems no data of this kind has been saved.
             </p>
        </div>

    )
};


export default NoData;