import React, { useState } from "react";
import { AccountCircle } from "@material-ui/icons";

const ProfilePicture = ({ imageLink }) => {
  const [imageError, setImageError] = useState(false);
  const handleImageErrored = () => {
    setImageError(true);
  };
  if (imageError) return <AccountCircle />;
  return (
    <img
      src={imageLink}
      alt="profile user "
      className="photo__img"
      onError={handleImageErrored}
    />
  );
};

export default ProfilePicture;
