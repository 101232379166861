import { useState, useEffect } from "react";
import { useSelectProviders } from "../../hooks/useSelectProviders";

export const useAdvertiserSelectLogic = (providerId = "") => {
  const { providers, provider, setProvider, loadingProviders } =
    useSelectProviders();
  const [openProviderChangeMsg, setOpenProviderChangeMsg] = useState(false);
  const [providerChangeId, setProviderChangeId] = useState(null);

  const handleModalClose = () => {
    setOpenProviderChangeMsg(false);
  };

  const changeProvider = () => {
    setProvider(providerChangeId);
    handleModalClose();
  };

  const handleProviderChange = (value, formTouched) => {
    //if no provider selected, change the provider without the confirm message
    if (provider === "" || !formTouched) {
      setProvider(value);
      return;
    }
    setProviderChangeId(value);
    setOpenProviderChangeMsg(true);
  };

  /**
   * Sets provider if provider Id passed
   */
  useEffect(() => {
    setProvider(providerId);
  }, [providerId, setProvider]);

  return {
    providers,
    provider,
    handleProviderChange,
    loadingProviders,
    openProviderChangeMsg,
    changeProvider,
    handleModalClose
  };
};
