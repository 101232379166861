import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/CloseSharp";
import CheckIcon from "@material-ui/icons/Check";
import DisplayNamesInfoMsg from "./DisplayNamesInfoMsg";
import { useEffect } from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { csv_mapping_order } from "../../../../helpers/constant/mapping";

const MappingFieldForm = ({
  values,
  handleChange,
  handleSubmit,
  handleCancel,
  displayNames,
  loadingMappedFields,
  isSubmittingData
}) => {
  /**
   * The page scrolls down when form loads as there are
   * many form fields being dynamically rendered.
   * The below code makes sure the page
   * does not scroll down when new provider is selected and
   * a new form is rendered!
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loadingMappedFields]);

  if (!displayNames) {
    return <DisplayNamesInfoMsg />;
  }

  if (loadingMappedFields) {
    return (
      <div className="loading-div">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="mappingField-form">
      <fieldset className="custom-fieldset flow-content">
        <form onSubmit={handleSubmit} className="flow-content--small">
          <div className="mappingField-form__row mappingField-form__row--header">
            <h3>Database Fields</h3>
            <h3>CSV Fields</h3>
          </div>
          {csv_mapping_order.map((field) => {
            return (
              <div key={field} className="mappingField-form__row">
                <div>{values[field].label}</div>
                <div>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label={values[field].label}
                      name={field}
                      value={values[field].value}
                      error={values[field].error}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder={`Enter Equivalent CSV field`}
                      type={values[field].type}
                    />
                    {values[field].error && (
                      <FormHelperText>{values[field].errorMsg}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
            );
          })}
          <div className="profile__basic-info__form__buttons">
            <Button
              color="primary"
              variant="contained"
              size="medium"
              startIcon={<CheckIcon />}
              type="submit"
              disabled={isSubmittingData}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              startIcon={<CloseIcon />}
            >
              Cancel
            </Button>
          </div>
        </form>
      </fieldset>
    </div>
  );
};

MappingFieldForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  displayNames: PropTypes.any,
  loadingMappedFields: PropTypes.bool.isRequired
};

export default MappingFieldForm;
