import React from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
/*
    This is a higher order component that uses the loading props
    of the wrapped component to either show a loading circle
    or the wrapped component.
*/
const withSpinner =
  (WrappedComponent) =>
  ({ isLoading, ...otherProps }) => {
    return isLoading ? (
      <LoadingSpinner />
    ) : (
      <WrappedComponent {...otherProps} />
    );
  };

export default withSpinner;
