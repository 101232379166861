import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { User } from "../../../services/ReportingUser";
import { useFormTouched } from "../../../hooks/useFormTouched";
export const useFormControls = () => {
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction: () => {}
  });
  const initialFormValues = {
    // username: "",
    first_name: "",
    last_name: "",
    email: "",
    publisher_id: "",
    role_id: "",
    role: {},
    publisher: {},
    // provider_id: "",
    // account_id: "",
    blocked: false
  };

  const [values, setValues] = useState(initialFormValues);
  const [userId, set_UserId] = useState(null);

  const fetchCurrentUser = async function () {
    if (userId) {
      const user = await User.fetchUser(userId);
      if (user.success) {
        setValues({
          ...values,
          // username: user?.data?.user?.username,
          first_name: user?.data?.user?.first_name,
          last_name: user?.data?.user?.last_name,
          email: user?.data?.user?.email,
          publisher_id: user?.data?.user?.publisher?.id
            ? user?.data?.user?.publisher?.id
            : null,
          role: user?.data?.user?.role,
          publisher: user?.data?.user?.publisher,
          role_id: user?.data?.user?.role.id,
          // provider_id: user?.data?.user?.provider?.id
          //   ? user?.data?.user?.provider?.id
          //   : null,
          // account_id: user?.data?.user?.account?.id
          //   ? user?.data?.user?.account?.id
          //   : null,
          blocked: user?.data?.user?.blocked
        });
      }
    }
  };

  useEffect(() => {
    fetchCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("username" in fieldValues) {
      temp.username = fieldValues.username ? "" : "Username is required.";
    }
    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "Email is required.";
      if (fieldValues.email) {
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
      }
    }
    if ("publisher_id" in fieldValues) {
      temp.publisher_id = fieldValues.publisher_id
        ? ""
        : "Publisher field is required.";
    }
    // if ("provider_id" in fieldValues) {
    //   temp.provider_id = fieldValues.provider_id
    //     ? ""
    //     : "Provider field is required.";
    // }
    // if ("account_id" in fieldValues) {
    //   temp.account_id = fieldValues.account_id
    //     ? ""
    //     : "Account field is required.";
    // }
    if ("role_id" in fieldValues) {
      temp.role_id = fieldValues.role_id ? "" : "Role field is required.";
    }
    setErrors({
      ...temp
    });
  };

  const PostContactForm = async (values, successCallback, errorCallback) => {
    delete values["role"];
    delete values["publisher"];
    const response = await User.updateUser(userId, values);
    if (response.success) {
      successCallback();
      toastr.success("Success", "User Updated Successfully!");
      history.push("/usermanagement");
    } else {
      toastr.error("Failed", response?.error?.message);
      errorCallback();
    }
  };

  const handleInputValue = (e) => {
    handleFormTouched();
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    validate({ [name]: value });
  };

  const handleSuccess = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: true
    });
  };

  const handleselectbox = (value, name) => {
    handleFormTouched();
    setValues({
      ...values,
      [name]: value
    });
    validate({ [name]: value });
  };

  const handleError = () => {
    // setValues({
    //   ...initialFormValues,
    //   formSubmitted: true,
    //   success: false
    // });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      // fieldValues.username &&
      fieldValues.email &&
      fieldValues.publisher_id &&
      // fieldValues.provider_id &&
      fieldValues.role_id &&
      // fieldValues.account_id &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  const handleFormSubmit = async (e, values) => {
    e.preventDefault();
    validate({ ...values });
    const isValid =
      Object.values(errors).every((x) => x === "") && formIsValid();
    if (isValid) {
      clearFormTouched();
      await PostContactForm(values, handleSuccess, handleError);
    }
  };

  return {
    values,
    errors,
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    set_UserId,
    formTouched,
    handleselectbox
  };
};
