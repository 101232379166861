import { useState } from "react";
import { SearchEngine } from "../../../services/SearchEngine";

export const useSubmitSearchEngineLogic = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveSearchEngine = async (data) => {
    try {
      setIsSubmitting(true);
      const searchEngine = await SearchEngine.addSearchEngine(data);
      setIsSubmitting(false);
      if (searchEngine.success) {
        return {
          success: true,
          data: searchEngine.data.searchEngine,
          msg: searchEngine.msg
        };
      } else {
        throw new Error(
          searchEngine?.error?.message || "Unable to save Search Engine"
        );
      }
    } catch (error) {
      setIsSubmitting(false);
      return {
        success: false,
        msg: error.message || "Unable to save Search Engine",
        data: null
      };
    }
  };

  const updateSearchEngine = async (id, data) => {
    try {
      setIsSubmitting(true);
      const searchEngine = await SearchEngine.updateSearchEngine(id, data);
      setIsSubmitting(false);
      if (searchEngine.success) {
        return {
          success: true,
          data: searchEngine.data.searchEngine,
          msg: searchEngine.msg
        };
      } else {
        throw new Error(
          searchEngine?.error?.message || "Unable to update Search Engine"
        );
      }
    } catch (error) {
      setIsSubmitting(false);
      return {
        success: false,
        msg: error.message || "Unable to update Search Engine",
        data: null
      };
    }
  };

  return {
    saveSearchEngine,
    isSubmitting,
    updateSearchEngine
  };
};
