import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { useModal } from "../../../hooks/useModal";
import { SearchEngine } from "../../../services/SearchEngine";

export const useSearchEngineTablelogic = ({
  filterValues,
  getSearchEngines,
  openEditForm
}) => {
  const [archiveModalOpen, openArchiveModal, closeArchiveModal] =
    useModal(false);
  const [archiveId, setArchiveId] = useState(null);

  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal(false);
  const [deleteId, setDeleteId] = useState(null);

  const [restoreModalOpen, openRestoreModal, closeRestoreModal] =
    useModal(false);
  const [restoreId, setRestoreId] = useState(null);

  function handleEditAction(id) {
    openEditForm(id);
  }

  function handleArchiveAction(id) {
    setArchiveId(id);
    openArchiveModal();
  }

  async function archiveSearchEngine() {
    try {
      const archivedSearchEngine = await SearchEngine.deleteSearchEngine(
        archiveId
      );
      if (archivedSearchEngine.success) {
        toastr.success("Success", "Search Engine has been archived");
        getSearchEngines();
        handleModalClose();
      } else {
        throw new Error(JSON.stringify(archivedSearchEngine.error));
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", "Not able to remove at the moment");
      handleModalClose();
    }
  }

  async function deleteSearchEngine() {
    try {
      const params = {
        permanent: true
      };
      const deletedSearchEngine = await SearchEngine.deleteSearchEngine(
        deleteId,
        params
      );
      if (deletedSearchEngine.success) {
        toastr.success(
          "Search Engine Deleted",
          "Search Engine has been deleted"
        );
        getSearchEngines();
        handleModalClose();
      } else {
        throw new Error(JSON.stringify(deletedSearchEngine.error));
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", "Not able to remove at the moment");
      handleModalClose();
    }
  }

  async function restoreSearchEngine() {
    try {
      const params = {
        archive: false
      };
      const dataToSend = {};
      const restoredSearchEngine = await SearchEngine.updateSearchEngine(
        restoreId,
        dataToSend,
        params
      );
      if (restoredSearchEngine.success) {
        toastr.success("Success", "Search Engine restored successfully");
        getSearchEngines();
        handleModalClose();
      } else {
        throw new Error(restoredSearchEngine.error);
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops", "Not able to restore search engine");
      handleModalClose();
    }
  }

  function handlePermanaentDeleteAction(id) {
    setDeleteId(id);
    openDeleteModal();
  }

  function handleRestoreAction(id) {
    setRestoreId(id);
    openRestoreModal();
  }

  const handleModalClose = () => {
    closeArchiveModal();
    closeDeleteModal();
    closeRestoreModal();
    setArchiveId(null);
    setDeleteId(null);
    setRestoreId(null);
  };

  const headers = [
    { title: "ID", value: "id" },
    { title: "Name", value: "name" },
    { title: "Details", value: "details" }
  ];

  let actions = [];
  if (!filterValues.archivedData) {
    actions = [
      {
        name: "edit",
        action: handleEditAction,
        icon: "editIcon"
      }
      // {
      //   name: "delete",
      //   action: handleArchiveAction,
      //   icon: "deleteIcon"
      // }
    ];
  } else {
    actions = [
      {
        name: "deleteForever",
        action: handlePermanaentDeleteAction,
        icon: "deleteForever"
      },
      {
        name: "restore",
        action: handleRestoreAction,
        icon: "restore"
      }
    ];
  }

  return {
    headers,
    actions,
    archiveModalOpen,
    deleteModalOpen,
    restoreModalOpen,
    handleModalClose,
    archiveSearchEngine,
    deleteSearchEngine,
    restoreSearchEngine,
    handleArchiveAction
  };
};
