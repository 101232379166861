import React from "react";

const InfoBox = ({ icon, header, children }) => {
  return (
    <div className="info-box box-shadow">
      <div className="info-box__icon">{icon}</div>
      <div className="info-box__contents">
        <div className="info-box__header">
          <h4>{header}</h4>
        </div>
        <div className="info-box__children">{children}</div>
      </div>
    </div>
  );
};

export default InfoBox;
