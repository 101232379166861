import React from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
const SavingMessage = () => {
  return (
    <div>
      <div className="big-messages">
        <h3>Sending Targeting Form Data...</h3>
        <LoadingSpinner />
      </div>
    </div>
  );
};

export default SavingMessage;
