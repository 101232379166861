import apiDetailsActionTypes from "./apiDetails.types";

const INITIAL_STATE = {
  error: null,
  isFetching: false,
  isFetchingsList: false,
  apiDetails: []
};

const apiDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case apiDetailsActionTypes.FETCH_START:
      return {
        ...state,
        isFetching: true
      };
    case apiDetailsActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case apiDetailsActionTypes.FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case apiDetailsActionTypes.ADD_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload
      };
    case apiDetailsActionTypes.FETCH_LIST_START:
      return {
        ...state,
        isFetchingsList: true
      };
    case apiDetailsActionTypes.FETCH_LIST_SUCCESS:
      return {
        ...state,
        isFetchingsList: false
      };
    case apiDetailsActionTypes.FETCH_LIST_FAILURE:
      return {
        ...state,
        isFetchingsList: false,
        error: action.payload
      };
    case apiDetailsActionTypes.UPDATE_LIST:
      return {
        ...state,
        apiDetails: action.payload
      };
    default:
      return { ...state };
  }
};

export default apiDetailsReducer;
