import React from "react";
import AutoCompleteSelect from "../../../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { STATUS } from "../constant/staticMessage";

const FilterBox = ({ message, setMessage }) => {
  const HandleMessageChange = (value) => {
    setMessage(value);
  };
  return (
    <div style={{ width: "371px" }}>
      <AutoCompleteSelect
        selected={message ? message : ""}
        data={STATUS || []}
        handleChange={(value) => HandleMessageChange(value)}
        label={"Message"}
        showValueInLabel={false}
        enableMultiple={false}
        withDotsForStatus={false}
        cannotSelectDisabled={false}
      />
    </div>
  );
};

export default FilterBox;
