import React from "react";

const TabMenu = ({
  sections,
  setCurrentSelectedSectionIndex,
  currentSelectedSectionIndex
}) => {
  return (
    <div className="tab__menu">
      <ul>
        <li>
          {sections.map((section, index) => (
            <span
              key={section.name}
              onClick={() => setCurrentSelectedSectionIndex(index)}
              className={`tab__menu__item ${
                currentSelectedSectionIndex === index &&
                "tab__menu__item--active"
              }`}
            >
              {section.name}
            </span>
          ))}
        </li>
      </ul>
    </div>
  );
};

export default TabMenu;
