import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { useCsvUploadLogic } from "../csv-upload.logic";
import DescriptionIcon from "@material-ui/icons/Description";
import Button from "@material-ui/core/Button";
import UploadSuccessBox from "./UploadSuccessBox";

const UploadBox = ({ providerId }) => {
  const {
    isUploading,
    handleBrowseAction,
    handleUploadChange,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    uploadCSVFile,
    fileUploadButtonRef,
    uploadFile,
    uploadSuccess,
    uploadAnotherFile
  } = useCsvUploadLogic(providerId);

  if (uploadSuccess.success)
    return (
      <UploadSuccessBox
        message={uploadSuccess.msg}
        uploadData={uploadSuccess.data}
        uploadAnotherFile={uploadAnotherFile}
      />
    );

  return (
    <>
      <div className="csv-upload__box dotted-box">
        {" "}
        {isUploading ? (
          <div className="csv-upload__loading">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div
              className="csv-upload__drop-area"
              onClick={handleBrowseAction}
              draggable="true"
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDragEnter={(e) => handleDragEnter(e)}
              onDragLeave={(e) => handleDragLeave(e)}
            >
              {uploadFile && (
                <div className="csv-upload__drop-area__uploaded-file">
                  <DescriptionIcon fontSize="inherit" />
                  <span className="upload-text">{uploadFile.name}</span>
                </div>
              )}
              <span className="csv-upload__drop-area__text upload-text">
                Drag a CSV File Here -or-
              </span>
              <button
                onClick={handleBrowseAction}
                className="csv-upload__drop-area__select-btn"
              >
                Select a CSV File
              </button>
              <input
                ref={fileUploadButtonRef}
                type="file"
                onChange={handleUploadChange}
                hidden
                accept="text/csv,.csv,text/*"
              />
            </div>
            <Button
              className="csv-upload__upload-btn"
              onClick={() => uploadCSVFile({ providerId })}
              disabled={isUploading}
              color="secondary"
            >
              Upload
            </Button>
          </>
        )}
      </div>
      {uploadSuccess.msg && (
        <span
          className="upload-text"
          style={{
            textAlign: "right",
            display: "block",
            width: "70%",
            marginLeft: "auto"
          }}
        >
          {uploadSuccess.msg}
        </span>
      )}
    </>
  );
};

UploadBox.propTypes = {
  providerId: PropTypes.any
};

export default UploadBox;
