import React from "react";
import SearchEngineFilterSelections from "./SearchEngineFilterSelections";
import SearchEngineFilterActionButtons from "./SearchEngineFilterActionButtons";
const SearchEngineTableFilter = ({
  clearAllSelection,
  handleSearch,
  filterValuesLogic,
  loadingSearchEngines
}) => {
  const { selectionAddActions, filterValues } = filterValuesLogic;

  return (
    <div>
      <SearchEngineFilterSelections
        selectionAddActions={selectionAddActions}
        filterValues={filterValues}
        handleSearch={handleSearch}
      />
      <SearchEngineFilterActionButtons
        handleSearch={handleSearch}
        clearAllSelection={clearAllSelection}
        loadingData={loadingSearchEngines.isLoading}
      />
    </div>
  );
};

export default SearchEngineTableFilter;
