export const HEADERS = [
  { title: "ID", value: "id" },
  { title: "Advertiser", value: "provider" },
  { title: "File Name", value: "csv_name" },
  { title: "Applied At (UTC)", value: "createdAt" },
  { title: "Message", value: "message" },
  { title: "Status", value: "uploaded_status" }
  // { title: "Total uploaded", value: "total_uploaded" },
  // { title: "Total skipped", value: "total_skipped" },
  // { title: "Total Rows", value: "total_rows" },
  // { title: "Checksum in CSV", value: "check_sum_totalSearches" },
  // { title: "Checksum in DB", value: "uploaded_sum_totalSearches" },
];
