import React from "react";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import RelatedTagBox from "./layouts/RelatedTagBox";
import PropTypes from "prop-types";

const RelatedProviderLinkModal = ({
  modalOpen,
  closeModal,
  targetObj,
  linkID
}) => {
  return (
    <>
      {modalOpen && (
        <SimpleModal handleClose={closeModal}>
          <RelatedTagBox relatedTags={targetObj} linkID={linkID} />
        </SimpleModal>
      )}
    </>
  );
};

RelatedProviderLinkModal.propTypes = {
  targetObj: PropTypes.array.isRequired,
  linkID: PropTypes.any.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default RelatedProviderLinkModal;
