export const HEADERS = [
  { title: "ID", value: "id" },
  { title: "Advertiser", value: "advertiser_id" },
  { title: "Last (UTC time)", value: "last_updated_db" },
  { title: "Activated (UTC time)", value: "called_api_date" },
  { title: "Message", value: "message" },
  { title: "Status", value: "uploaded_status" }
  // { title: "Uploaded SubIds", value: "uploaded_subid" },
  // { title: "Skipped SubIds", value: "skipped_subid" }
  // { title: "From", value: "apply_from_data" },
  // { title: "To", value: "apply_to_date" }
];

export const ROW_STATUS_HEADERS = [
  { title: "Row Index", value: "row_index" },
  // { title: "Advertiser Name", value: "advertiser_id" },
  { title: "Channel", value: "channel" },
  { title: "Date", value: "date" },
  { title: "Gross revenue", value: "gross_revenue" },
  { title: "Clicks", value: "clicks" },
  { title: "Total Searches", value: "total_searches" },
  { title: "Monetized Searches", value: "monetized_searches" },
  { title: "message", value: "message" },
  { title: "Created At(UTC Time)", value: "createdAt" }
];
