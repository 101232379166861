import { useState } from "react";
import { Platform } from "../../../services/Platform";

export const useSubmitPlatformLogic = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const savePlatform = async (data) => {
    try {
      setIsSubmitting(true);
      const platform = await Platform.addPlaform(data);
      setIsSubmitting(false);
      if (platform.success) {
        return {
          success: true,
          data: platform.data.platform,
          msg: platform.msg
        };
      } else {
        throw new Error(platform?.error?.message || "Unable to save Platform");
      }
    } catch (error) {
      setIsSubmitting(false);
      return {
        success: false,
        msg: error.message || "Unable to save Platform",
        data: null
      };
    }
  };

  const updatePlatform = async (id, data) => {
    try {
      setIsSubmitting(true);
      const platform = await Platform.updatePlatform(id, data);
      setIsSubmitting(false);
      if (platform.success) {
        return {
          success: true,
          data: platform.data.platform,
          msg: platform.msg
        };
      } else {
        throw new Error(
          platform?.error?.message || "Unable to update Platform"
        );
      }
    } catch (error) {
      setIsSubmitting(false);
      return {
        success: false,
        msg: error.message || "Unable to update Platform",
        data: null
      };
    }
  };

  return {
    savePlatform,
    isSubmitting,
    updatePlatform
  };
};
