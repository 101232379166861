import React from "react";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";

import ReportTable from "./Table/ReportTable";
import Stats from "./Stats";
import SimpleAccordion from "../../../../components/Accordion/Accordion";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import ColumnFilters from "../../../../components/ColumnsFilter/ColumnsFilter";

const ReportsBody = ({
  dashboardDataHook,
  displayNames,
  loadingDisplayNames,
  tableLogicHook,
  downloadReport,
  displayColumns,
  orderBy,
  orderDirection,
  setOrderBy,
  setOrderDirection
}) => {
  const { dashboardData, loadingDashboardData } = dashboardDataHook;

  if (loadingDashboardData || loadingDisplayNames) {
    return (
      <div className="loading-div">
        <LoadingSpinner />
      </div>
    );
  }

  if (!dashboardData) {
    return <pre>No data to display</pre>;
  }
  const { tableHeaders, filteredTableHeaders, updateHeadersShowValue } =
    tableLogicHook;

  let { list, stats, total } = dashboardData;
  let reportStats = stats?.[0] || {};
  let { fields } = displayNames || {}; //Mapped Display Name Fields

  return (
    <div className="dashboard-report flow-content">
      {/* <h2 className="dashboard-subheading">Report Table</h2> */}
      <Stats
        reportStats={reportStats}
        fields={fields}
        displayColumns={displayColumns}
      />
      {!loadingDashboardData && list && list?.length > 0 && (
        <>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={downloadReport}
            startIcon={<GetAppIcon />}
            className="download-btn"
          >
            Download
          </Button>
          <SimpleAccordion header="Show Table Columns" defaultExpanded={true}>
            <ColumnFilters
              columns={tableHeaders}
              handleCheckboxChange={updateHeadersShowValue}
              dynamicTableFilters={true}
            />
          </SimpleAccordion>
        </>
      )}
      <ReportTable
        reportData={list ? list : []}
        loadingDashboardData={loadingDashboardData}
        total={total ? total : 0}
        dashboardDataHook={dashboardDataHook}
        tableHeaders={filteredTableHeaders}
        orderBy={orderBy}
        orderDirection={orderDirection}
        setOrderBy={setOrderBy}
        setOrderDirection={setOrderDirection}
      />
    </div>
  );
};

export default ReportsBody;
