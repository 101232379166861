import React from "react";
import LinkBox from "./layouts/LinkBox";

const Links = ({
  filteredProviderLinks,
  providerLinksError,
  handleDeleteAction,
  handleLinkFormValueChange,
  platforms,
  searchEngines,
  tagTypes
}) => {
  return (
    <>
      {filteredProviderLinks.map((providerLink, index) => {
        return (
          <LinkBox
            key={`${providerLink.id || index}`}
            linkValues={providerLink}
            linkIndex={providerLink.linkIndex}
            handleLinkFormValueChange={handleLinkFormValueChange}
            handleDeleteAction={handleDeleteAction}
            linkErrors={providerLinksError[providerLink.linkIndex]}
            platforms={platforms}
            searchEngines={searchEngines}
            tagTypes={tagTypes}
          />
        );
      })}
    </>
  );
};

export default Links;
