import { useState, useEffect } from "react";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { SearchEngine } from "../../../services/SearchEngine";

export const useFetchSearchEngines = () => {
  const [searchEngines, setSearchEngines] = useState([]);

  const [loadingSearchEngines, setLoadingSearchEngines] = useState({
    isLoading: false,
    error: false
  });

  const isMounted = useIsMounted();

  function getParams() {
    let params = {};

    params["order_by"] = "id";
    params["order_direction"] = "ASC";
    params["size"] = DEFAULT_FETCH_PARAM_SIZE;

    return params;
  }

  async function getSearchEngines() {
    try {
      setLoadingSearchEngines({ isLoading: true, error: false });
      const searchEngines = await SearchEngine.fetchSearchEngines(getParams());
      if (searchEngines.success && isMounted()) {
        let receivedSearchEngines = [];
        for (let p of searchEngines.data.search_engines) {
          receivedSearchEngines.push({
            label: p.name,
            value: p.id,
            status: p.status
          });
        }
        setSearchEngines(receivedSearchEngines);
        setLoadingSearchEngines({ isLoading: false, error: false });
      } else {
        throw new Error(searchEngines.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setSearchEngines([]);
        setLoadingSearchEngines({ isLoading: false, error: true });
      }
      console.trace(error.message || error);
    }
  }

  useEffect(() => {
    getSearchEngines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loadingSearchEngines,
    searchEngines
  };
};
