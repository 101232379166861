import React from "react";
import PropTypes from "prop-types";
import FilterActionButtons from "./FilterActionButtons";
import FilterSelections from "./FilterSelections";

const FilterBox = ({
  handleSearch,
  filterValuesLogic,
  loadingDashboardData,
  displayColumns
}) => {
  const { selectionAddActions, filterValues, dataLists, clearAllSelection } =
    filterValuesLogic;

  return (
    <div className="flow-content dashboard-report__filters">
      <FilterSelections
        dataLists={dataLists}
        filterValues={filterValues}
        selectionAddActions={selectionAddActions}
        displayColumns={displayColumns || []}
      />
      <FilterActionButtons
        handleSearch={handleSearch}
        loadingDashboardData={loadingDashboardData}
        clearAllSelection={clearAllSelection}
      />
    </div>
  );
};

FilterBox.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  filterValuesLogic: PropTypes.object.isRequired,
  displayColumns: PropTypes.array.isRequired
};

export default FilterBox;
