import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";

import RevenueShareForm from "./Layouts/Form.layout";
import ActionButton from "./Layouts/ActionButton.layout";
import { ShareRevenue } from "../../services/ShareRevenue";

const RevenueShare = ({ setIndex }) => {
  const [revenueShare, setRevenueShare] = useState(null);
  const [isExist, setIsExist] = useState(false);

  useEffect(() => {
    getShareRevenue();
  }, [revenueShare]);

  const getShareRevenue = async () => {
    try {
      const result = await ShareRevenue.getShareRevenue();
      if (result.data && result.success) {
        setRevenueShare(result.data.share_revenue);
        setIsExist(true);
      }
    } catch (error) {
      toastr.warning("Oops!", error?.message);
    }
  };

  const onCancel = () => setIndex(0);
  const onSubmit = async (data = {}) => {
    try {
      const addShareRevenue = await ShareRevenue.editUpdateShareRevenue(data);
      if (addShareRevenue.success) {
        toastr.success("Success", `Revenue Share Applied Successfully`);
      } else {
        throw new Error("Something Went Wrong");
      }
    } catch (error) {
      toastr.warning("Oops!", error?.message);
    }
  };

  return (
    <div>
      {!isExist && <ActionButton isExist={isExist} setIsExist={setIsExist} />}
      {isExist && (
        <RevenueShareForm
          revenueShare={revenueShare}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default RevenueShare;
