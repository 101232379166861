import apiDetailsActionTypes from "./apiDetails.types";

export const addError = () => ({
  type: apiDetailsActionTypes.ADD_ERROR_MESSAGE,
  payload: "Sorry, Facing some errors."
});

export const fetchStart = () => ({
  type: apiDetailsActionTypes.FETCH_START
});

export const fetchSuccess = () => ({
  type: apiDetailsActionTypes.FETCH_SUCCESS
});

export const fetchFailure = (error) => ({
  type: apiDetailsActionTypes.FETCH_FAILURE,
  payload: error
});

export const fetchListStart = () => ({
  type: apiDetailsActionTypes.FETCH_LIST_START
});

export const fetchListSuccess = () => ({
  type: apiDetailsActionTypes.FETCH_LIST_SUCCESS
});

export const fetchListFailure = (error) => ({
  type: apiDetailsActionTypes.FETCH_LIST_FAILURE
});

export const updateList = (accounts) => ({
  type: apiDetailsActionTypes.UPDATE_LIST,
  payload: accounts
});
