import React from "react";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
const RelatedProviderLinkWarningModal = ({
  modalOpen,
  closeModal,
  relatedActiveRules
}) => {
  return (
    <>
      {modalOpen && (
        <SimpleModal handleClose={closeModal}>
          <div className="active-rules__warning-modal">
            <h2 className="dashboard-subheading">
              {" "}
              Link used in Active Rules{" "}
            </h2>
            <h3>
              This Advertiser link is connected to active Rules:{" "}
              {relatedActiveRules.map((rule, index) => {
                if (index < relatedActiveRules.length - 1) {
                  return `${rule},`;
                } else {
                  return `${rule}`;
                }
              })}
              . Please deactivate the rules before deactivating this Advertiser
              link
            </h3>
            <Button
              color="primary"
              onClick={closeModal}
              variant="outlined"
              size="medium"
            >
              OK
            </Button>
          </div>
        </SimpleModal>
      )}
    </>
  );
};

RelatedProviderLinkWarningModal.propTypes = {
  relatedActiveRules: PropTypes.array.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default RelatedProviderLinkWarningModal;
