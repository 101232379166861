import React from "react";

const VerticalTabMenu = ({
  sections,
  setCurrentSelectedSectionIndex,
  currentSelectedSectionIndex
}) => {
  return (
    <div className="vertical-tab__menu">
      <ul>
        <li>
          {sections.map((section, index) => (
            <div
              key={section.name}
              onClick={() => setCurrentSelectedSectionIndex(index)}
              className={`vertical-tab__menu__item ${
                currentSelectedSectionIndex === index &&
                "vertical-tab__menu__item--active"
              }`}
            >
              {section.name}
            </div>
          ))}
        </li>
      </ul>
    </div>
  );
};

export default VerticalTabMenu;
