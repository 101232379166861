import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const ColumnFilters = ({
  columns,
  handleCheckboxChange,
  dynamicTableFilters = false
}) => {
  const onCheckBoxChange = (value, index) => {
    if (dynamicTableFilters) {
      handleCheckboxChange(value, index);
    } else {
      handleCheckboxChange(value);
    }
  };

  return (
    <div className="column-filter-container">
      <div className="column-filter__checkboxes">
        {columns.map((column, index) => {
          return (
            <FormControlLabel
              key={column.value + column.show}
              className="column-filter__label"
              control={
                <Checkbox
                  checked={column.show}
                  onChange={() => onCheckBoxChange(column.value, index)}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  name={column.value}
                  className="column-filter__checkbox"
                />
              }
              label={column.title}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ColumnFilters;
