import React from "react";
import PublisherAccountFilterSelections from "./PublisherAccountFilterSelections";
import PublisherAccountFilterActionButtons from "./PublisherAccountActionButtons";
const PublisherAccountFilter = ({
  filterValuesLogic,
  handleSearch,
  loadingpublisherAccounts
}) => {
  const { filterValues, selectionAddActions, clearAllSelection, dataLists } =
    filterValuesLogic;
  return (
    <div className="flow-content publisherAccounts-filter__container" style={{"width":"100%"}}>  
      <PublisherAccountFilterSelections
        selectionAddActions={selectionAddActions}
        filterValues={filterValues}
        handleSearch={handleSearch}
        dataLists={dataLists}
      />
      <PublisherAccountFilterActionButtons
        handleSearch={handleSearch}
        clearAllSelection={clearAllSelection}
        loadingData={loadingpublisherAccounts.isLoading}
      />
    </div>
  );
};

export default PublisherAccountFilter;
