import { useState } from "react";
export const useSelect = (initialValue = "", initialData = []) => {
  const [data, setData] = useState(initialData);
  const [selectedData, setSelectedData] = useState(initialValue);

  function changeSelectedData(value) {
    setSelectedData(value);
  }

  function clearSelection() {
    setSelectedData("");
  }

  return {
    data,
    changeSelectedData,
    clearSelection,
    selectedData,
    setData
  };
};
