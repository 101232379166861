export const useSkippedRowsTableLogic = () => {
  const headers = [
    { title: "Row Index", value: "row_index" },
    // { title: "Advertiser Name", value: "provider" },
    // { title: "CSV File", value: "csvfile" },
    { title: "Channel", value: "channel" },
    { title: "Date", value: "date" },
    { title: "Gross Revenue", value: "gross_revenue" },
    { title: "Clicks", value: "clicks" },
    { title: "Total Searches", value: "total_searches" },
    { title: "Monetized Searches", value: "monetized_searches" },
    { title: "Message", value: "message" },
    { title: "Created At(Utc Time)", value: "createdAt" }
  ];

  return {
    headers
  };
};
