import PublisherAccountActionTypes from "./publisherAccount.types";

const INITIAL_STATE = {
  error: null,
   isFetching: false,
   isFetchingsList: false,
   publisherAccounts: []
};

const publisherAccountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PublisherAccountActionTypes.FETCH_START:
      return {
        ...state,
         isFetching: true
      };
    case PublisherAccountActionTypes.FETCH_SUCCESS:
      return {
        ...state,
         isFetching: false
      };
    case PublisherAccountActionTypes.FETCH_FAILURE:
      return {
        ...state,
         isFetching: false,
        error: action.payload
      };
    case PublisherAccountActionTypes.ADD_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload
      };
    case PublisherAccountActionTypes.FETCH_LIST_START:
      return {
        ...state,
         isFetchingsList: true
      };
    case PublisherAccountActionTypes.FETCH_LIST_SUCCESS:
      return {
        ...state,
         isFetchingsList: false
      };
    case PublisherAccountActionTypes.FETCH_LIST_FAILURE:
      return {
        ...state,
         isFetchingsList: false,
        error: action.payload
      };
    case PublisherAccountActionTypes.UPDATE_LIST:
      return {
        ...state,
        publisherAccounts: action.payload
      };
    default:
      return { ...state };
  }
};

export default publisherAccountReducer;
