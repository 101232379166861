const UserActionTypes = {
    FETCH_USER_START: "FETCH_USER_START",
    FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
    FETCH_USER_FAILURE: "FETCH_USER_FAILURE",
    ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
    FETCH_USERS_LIST_START: "FETCH_USERS_LIST_START",
    FETCH_USERS_LIST_FAILURE: "FETCH_USERS_LIST_FAILURE",
    FETCH_USERS_LIST_SUCCESS: "FETCH_USERS_LIST_SUCCESS",
    UPDATE_USERS_LIST: "UPDATE_USERS_LIST"
  };
  
  export default  UserActionTypes;
  