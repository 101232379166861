import UserActionTypes from "./user.types";

export const addError = () => ({
  type: UserActionTypes.ADD_ERROR_MESSAGE,
  payload: "Sorry, Facing some errors."
});

export const fetchUserStart = () => ({
  type: UserActionTypes.FETCH_USER_START
});

export const fetchUserSuccess = () => ({
  type: UserActionTypes.FETCH_USER_SUCCESS
});

export const fetchUserFailure = (error) => ({
  type: UserActionTypes.FETCH_USER_FAILURE,
  payload: error
});

export const fetchUsersListStart = () => ({
  type: UserActionTypes.FETCH_USERS_LIST_START
});

export const fetchUsersListSuccess = () => ({
  type: UserActionTypes.FETCH_USERS_LIST_SUCCESS
});

export const fetchUsersListFailure = (error) => ({
  type: UserActionTypes.FETCH_USERS_LIST_FAILURE
});

export const updateUsersList = (users) => ({
  type: UserActionTypes.UPDATE_USERS_LIST,
  payload: users
});
