import * as React from "react";

const RevColHeadersContext = React.createContext();

function revColHeadersReducer(state, action) {
  switch (action.type) {
    case "update headers":
      return {
        ...state,
        tableHeaders: action.payload
      };
    default: {
      throw new Error(`Unhandled action type: , ${action.type}`);
    }
  }
}

export function RevColHeadersContextProvider(props) {
  const [state, dispatch] = React.useReducer(revColHeadersReducer, {
    tableHeaders: []
  });
  const value = [state, dispatch];

  return <RevColHeadersContext.Provider value={value} {...props} />;
}

export function useRevColHeadersContext() {
  const context = React.useContext(RevColHeadersContext);
  if (context === undefined) {
    throw new Error(
      "useRevColHeadersContext must be used within RevColHeadersContextProvider"
    );
  }
  return context;
}

export const updateRevColHeaders = (dispatch, payload) => {
  dispatch({ type: "update headers", payload });
};
