export const STATUS = [
    {
      label: "Missing publisher ad account",
      value: "Missing publisher ad account"
    },
    {
      label: "Uploaded",
      value: "uploaded"
    },
    {
      label: "Validation failed",
      value: "Validation failed"
    }
  ];
  