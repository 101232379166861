import { useState, useEffect } from "react";
import { useTextInput } from "../../../hooks/useTextInput";
import useDebounce from "../../../hooks/useDebounce";

export const useFilterProviderLinks = (providerLinks, providerLinksError) => {
  const [filterText, onFilterTextChange, setFilterText] = useTextInput({
    initialValue: ""
  });
  const debouncedFilterText = useDebounce(filterText, 500);
  const [filteredProviderLinks, setFilteredProviderLinks] =
    useState(providerLinks);

  useEffect(() => {
    if (debouncedFilterText) {
      setFilteredProviderLinks(
        providerLinks.filter((providerLink, linkIndex) => {
          providerLink.linkIndex = linkIndex;
          if (!providerLink?.link) {
            return false;
          }
          return providerLink.link
            .toLowerCase()
            .includes(debouncedFilterText.toLowerCase());
        })
      );
    } else {
      setFilteredProviderLinks(
        providerLinks.map((providerLink, linkIndex) => {
          providerLink.linkIndex = linkIndex;
          return providerLink;
        })
      );
    }
  }, [debouncedFilterText, providerLinks, providerLinksError]);

  return {
    filteredProviderLinks,
    filterText,
    onFilterTextChange,
    setFilterText
  };
};
