import React ,{useState,useEffect}from "react";
import FormLayout from "./layouts/Form.layout";
import { toastr } from "react-redux-toastr";
import { EmailConfigService } from "../../services/EmailConfig";

const EmailConfig = ({ setIndex }) => {
  const [emaildata,setemaildata]=useState(null)
  const onCancel = () => setIndex(0);
  const getEmailConfig = async () => {
    try {
      const result = await EmailConfigService.getEmailConfig();
      if (result?.data && result?.success && result?.data?.emails?.length>0 ) {
        setemaildata(result?.data?.emails[0]?.email);
      }
    } catch (error) {
      toastr.warning("Oops!", error?.message);
    }
  };

  useEffect(() => {
    getEmailConfig();
  }, [emaildata]);

  const onSubmit = async (data = {}) => {
    try {
      const added = await EmailConfigService.editUpdateEmailConfig(data);
      if (added.success) {
        toastr.success("Success", `Successfully Updated/Added`);
      } else {
        throw new Error("Something Went Wrong");
      }
    } catch (error) {
      toastr.warning("Oops!", error?.message);
    }
  };
  return (
    <div>
      <FormLayout onCancel={onCancel} onSubmit={onSubmit} emaildata={emaildata}/>
    </div>
  );
};

export default EmailConfig;
