import React from "react";
import TableCell from "@material-ui/core/TableCell";

const HoverActionCell = ({
  header,
  data,
  hoverButtonMsg,
  hoverButtonAction
}) => {
  return (
    <TableCell key={`hover-cell+${header}`} className="hover-cell">
      <div>
        {data?.[header] ? data?.[header].toString() : ""}
        <button onClick={hoverButtonAction} className="hover-cell__button btn">
          {hoverButtonMsg}
        </button>
      </div>
    </TableCell>
  );
};

export default HoverActionCell;
